import Skeleton from "@material-ui/lab/Skeleton";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated } from "react-animated-css";
import { Form, Modal, ProgressBar } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { unstable_batchedUpdates } from "react-dom";
import {
  AiOutlineFile,
  BsCheck,
  BsCheckAll,
  BsChevronDown,
  BsChevronUp,
  BsFillReplyFill,
  FiChevronDown,
  MdClose,
} from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callAPI, callAPI2 } from "../../helper/apiUtils";
import {
  API_ROOT,
  BASE_URL,
  KJUUP_COLORS,
  escapeHtml,
  makeid,
} from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { $user } from "../../helper/UserFactory";
import { ReactComponent as GoogleMap } from "../../Icons/GoogleMap.svg";
import { ReactComponent as TrashIcon } from "../../Icons/Trashcan.svg";
import IconButton from "@material-ui/core/IconButton";
import {
  ALL_BADGE_COUNT,
  DRAFT_MESSAGES,
  FORWARD_MESSAGE,
  FOR_OPEN_CHAT_ID,
  GROUPS,
  MESSAGE_IDS,
  OPEN_CHATS,
  SET_ACTIVITY_MESSAGES,
  SET_ACTIVITY_MESSAGES_REMINDERS,
  SET_VIEWER_IINITAL_INDEX,
  SET_VIEWER_IMAGES,
  UPDATE_GROUP_MESSAGE,
} from "../../store";
import EmojiPickerDialog from "../Project/Dialogs/EmojiPickerDialog";
import ChatShareMap from "./ChatMap/ChatShareMap";
import FloatingAction from "./Dialogs/FloatingAction";
import TranslateDialog from "../../components/TranslateDialog";
import DocViewer from "./DocViewer";
import MediaDocs from "./MediaDocs";
import MessageInfo from "./MessageInfo";
import ShareContact from "./ShareContact";
import "./SingleChat.css";
import UserInfo from "./UserInfo";
import Dropzone from "../../components/Dropzone";
import InfiniteScroll from "react-infinite-scroll-component";
import { sumTotalChatHistory } from "../../helper/SocketObj";
import { urlify } from "../../helper/convertor";
import { Oval } from "react-loader-spinner";
// import Tooltip from "react-tooltip";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { SocketContext } from "../../context/socket";
import PlayerFeed from "../../components/PlayerFeed";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import "react-h5-audio-player/lib/styles.css";
import AllImages from "./AllImages";
import DownloadFileWithIcon from "../../components/DownloadFileWithIcon";
import i18n from "@emoji-mart/data/i18n/de.json";
import {
  decryptMessage,
  emojiDecode,
  emojiUnicode,
  encryptMessage,
} from "../../utils/encryptDecryptFunction";
import ActiveInactiveLable from "../../components/ActiveInactiveLable";
import EmojiPicker from "emoji-picker-react";
import { copyImageToClipboard, requestClipboardWritePermissio, getBlobFromImageElement, copyBlobToClipboard } from 'copy-image-clipboard'

const extractUrls = require("extract-urls");

const contentStyle = {
  height: "27vw",
  width: "19.5vw",
  borderWidth: 0,
  // backgroundColor: "transparent",
  // background: "transparent",
  marginLeft: "0.7vw",
};
const overlayStyle = { background: "rgba(0,0,0,1)" };
const arrowStyle = { color: "#fff", marginRight: 10 };

function Conversation(props) {
  const dispatch = useDispatch();
  const { user, activityMessagesReminders, unSeenAllCount, activityMessages } =
    useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [connectObj, setConnectObj] = useState({
    url: `${API_ROOT}/group/${props.chat.id}/getChat?page=${page}&limit=20`,
    method: "get",
    headers: {
      Authorization: `jwt ${$user.getToken()}`,
    },
  });
  const groupUnsubscribeObj = {
    url: `${API_ROOT}/group/${props.chat.id}/unsubscribe`,
    method: "get",
    headers: {
      Authorization: `jwt ${$user.getToken()}`,
    },
  };
  const [chatDetails, setChatDetails] = useState({});
  let [tempChatHistory, setTempChatHistory] = useState([]);
  let [tempChatHistoryTEMP, setTempChatHistoryTEMP] = useState([]);
  const [NewMess, setNewMess] = useState(false);
  const [secretKey, setSecretKey] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState(true);
  const [rows, setRows] = useState(1);
  const [mediaDoc, setMediaDoc] = useState(false);
  const [floatingAction, setFloatingAction] = useState(false);
  const [viewInfo, setViewInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [typingUser, setTypingUser] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [viewDoc, setViewDoc] = useState(false);
  const [doc, setDoc] = useState("");
  const [show, setShow] = useState(false);
  const [searchView, setSearchView] = useState(false);
  const [search, setSearch] = useState("");
  const [showContactSection, setShowContactSection] = useState(false);
  const [showMessageInfo, setMessageInfo] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [originalMessages, setOriginalMessages] = useState([]);
  const [totalHighlights, setTotalHighlights] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [highlightIds, setHighlightsIds] = useState([]);
  const [forward, setForward] = useState(false);
  const [msgIds, setMsgIds] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState("");
  const [delMessage, setDelMessage] = useState(false);
  const [activeReply, setActiveReply] = useState(false);
  const [showTranslateDialog, setTranslateDialog] = useState(false);
  const [triggerTranslate, setTriggerTranslate] = useState(false);
  const [audioRecording, setAudioRecording] = useState(false);
  const [openEmojiPicker, setEmojiPicker] = useState(false);
  const [dropfiles, setDropFiles] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [isSeen, setIsSeen] = useState(false);
  const [getChatId, setChatId] = useState(props.chat.id);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorId, setAnchorId] = useState("tooltip-anchor");
  const Socket = useContext(SocketContext);
  const ttop = useRef();
  const emojiRef = useRef(null);
  const { isGerm, setIsGerm } = React.useContext(LanguageContext);
  const textRef = useRef(null);
  const [isRead, setIsRead] = useState(null);
  const [makeOnOff, setMakeOnOff] = useState(null);
  const [isDeli, setIsDeli] = useState(null);
  const [isGettingChats, setIsGettingChats] = useState(false);
  const [visible, setVisible] = useState(true);
  const [axiosReq, setAxiosReq] = useState(null);
  const [searchIndex, setSearchIndex] = useState(0);
  const [replyMessage, setReplyMessage] = useState(null);
  const [recordState, setRecordState] = useState(null);
  const [recordingCounter, setRecordingCounter] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [emojiModal, setEmojiModal] = useState({});
  const [emojiMessage, setEmojiMessage] = useState(null);
  const emojiCategories = [
    {
      category: "suggested",
      name: isGerm ? DE.frequent : "Frequently used",
    },
    {
      category: "smileys_people",
      name: isGerm ? DE.people : "Smileys & People",
    },
    {
      category: "animals_nature",
      name: isGerm ? "Tiere & Natur" : "Animals & Nature",
    },
    {
      category: "food_drink",
      name: isGerm ? "Essen & Trinken" : "Food & Drink",
    },
    {
      category: "activities",
      name: isGerm ? "Aktivität" : "Activity",
    },
    {
      category: "travel_places",
      name: isGerm ? "Reisen & Orte" : "Travel & Places",
    },
    {
      category: "objects",
      name: isGerm ? "Objekte" : "Objects",
    },
    {
      category: "symbols",
      name: isGerm ? "Symbole" : "Symbols",
    },
    {
      category: "flags",
      name: isGerm ? "Flaggen" : "Flags",
    },
  ];

  const getBadgesCount = async (chatId) => {
    const { data } = await callAPI(`/badge/count`, {}, "GET");
    const updateGroups = props.groups.map((g) => {
      if (g.id === chatId) {
        return { ...g, unseenChatCount: 0, isOpened: true };
      } else {
        return { ...g };
      }
    });
    await props.updateGroups(updateGroups);
    if (data?.status) {
      await props.updateAllBadgeCount(data);
    }
  };
  useEffect(() => {
    // let { data } = callAPI(`/message/${props.chat.id}/seen`, {}, "PUT");
    // console.log("res=>", data)
    if (props.chat.id) {
      getBadgesCount(props.chat.id);
    }
  }, [props.chat.id]);

  const onDrop = useCallback((acceptedFiles) => {
    setDropFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          url: URL.createObjectURL(file),
        })
      )
    );
    setFloatingAction(true);
  }, []);

  const showLocationDialog = () => {
    setShow(true);
    setFloatingAction(false);
  };
  const hideLocationDialog = () => setShow(false);
  const messagesEndRef = React.createRef();

  const getDetails = async (chatId) => {
    const id = chatId;
    if (user) {
      const { data } = await callAPI(`/group/${id}/info`, {}, "GET");
      if (data.status) {
        const chatUser = await data.groupInfo.users.filter(
          (u) => u.id !== user?.id
        );
        setChatDetails(chatUser[0]);
        setLoaded(true);
        setRows(1);
      }
      scrollToBottom(chatId);
    }
  };

  useEffect(() => {
    if (showAllImages) {
    }
  }, [showAllImages]);

  const handleTranslateMessage = (chatMessage, id) => {
    let tempChatIndex = tempChatHistory?.findIndex((item) => item.id === id);
    if (tempChatIndex > -1) {
      tempChatHistory[tempChatIndex].data = tempChatHistory[
        tempChatIndex
      ].data.map((chat) => {
        chat.items.map((c) => {
          if (c.id === chatMessage.id) {
            c.originalMessage = chatMessage.originalMessage;
            c.messageLng = chatMessage.messageLng;
            c.message = chatMessage.message;
          }
          return c;
        });
        return chat;
      });
    }
    setTempChatHistory(tempChatHistory);
    setTriggerTranslate(true);
  };
  const handleTranslate = (chat) => {
    setChatMessage(chat);
    setTranslateDialog(true);
  };
  const mergeConsecutiveImages = (sections) => {
    return sections.map((objects) => {
      let updatedItems = objects.items.reverse().reduce((result, current) => {
        if (!current.isMultiples) {
          const last = result[result.length - 1];
          if (current.messageType === "image") {
            if (last && last.messageType === "image") {
              const lastImageTime = new Date(
                last.images[last.images.length - 1].createdAt
              ).getTime();
              const currentTime = new Date(current.createdAt).getTime();
              const timeDifference = Math.floor(
                (lastImageTime - currentTime) / (1000 * 60)
              );
              if (
                last.senderId === current.senderId &&
                timeDifference <= 1 &&
                decryptMessage(last.message, secretKey) ===
                decryptMessage(current.message, secretKey)
              ) {
                last.isMultiples = true;
                last.images.push({ ...current });
                last.count = last.images.length;
              } else {
                result.push({ ...current, images: [{ ...current }], count: 1 });
              }
            } else {
              result.push({ ...current, images: [{ ...current }], count: 1 });
            }
          } else {
            result.push({ ...current });
          }
        } else {
          result.push({ ...current });
        }
        return result;
      }, []);
      updatedItems = updatedItems.map((item, index) => {
        if (item.images && item.images.length > 1) {
          return { ...item, images: item.images.reverse() }
        }
        else {
          return item
        }
      })
      return { ...objects, items: updatedItems.reverse() };
    });
  };

  useEffect(() => {
    if (tempChatHistoryTEMP && tempChatHistoryTEMP.length > 0) {
      _mmmerg();
    }
  }, [tempChatHistoryTEMP]);

  const _mmmerg = async () => {
    let xcz = [...tempChatHistoryTEMP];
    let pp = await mergeConsecutiveImages(xcz[0].data);
    xcz[0] = { ...xcz[0], data: [...pp] };
    setTempChatHistory([...xcz]);
  };

  const groupByDate = async (chat) => {
    setIsGettingChats(false);
    const chatData = await Promise.all(
      chat?.map(async (message) => {
        return {
          ...message,
          originalMessage: message.message,
          messageLng: props.language ? props.language : "en",
          message: message.message,
        };
      })
    );
    let grouping = await _.groupBy(chatData, (element) =>
      moment(element.createdAt).format("D MMM, Y")
    );
    let sections = await _.map(grouping, (items, createdAt) => ({
      createdAt: createdAt,
      items: items,
    }));
    sections = _.orderBy(
      sections,
      [
        function (object) {
          return new Date(object.createdAt);
        },
      ],
      ["desc"]
    );
    if (sections?.length > 0) {
      let tempChatIndex = tempChatHistory?.findIndex(
        (item) => item.id === props.chat.id
      );
      if (tempChatIndex > -1) {
        let xx = [...tempChatHistory[tempChatIndex].data];
        let tempData = [...xx, ...sections];
        let tx = [...tempChatHistory];
        tx[tempChatIndex].data = tempData;
        setTempChatHistoryTEMP([...tx]);
      } else {
        let x = [];
        x.push({ id: props.chat.id, data: [...sections] });
        setTempChatHistoryTEMP(x);
      }
    }
    setOriginalMessages(sections);
    // scrollToBottom(props.chat.id);
  };
  const deleteMessage = async () => {
    setDelMessage(true);
  };
  const deleteSelectedMessages = async (chatId) => {
    const groupId = chatId;
    let txx = [...tempChatHistory];
    let fillt = txx[0].data.map((u, i) => {
      let itts = u.items.map((e, j) => {
        let isExist = msgIds.includes(e.id);
        if (isExist) {
          return { ...e, isDeleted: true };
        } else {
          return { ...e };
        }
      });
      return { ...u, items: itts };
    });
    txx[0] = { ...txx[0], data: [...fillt] };
    setTempChatHistory([...txx]);
    setDelMessage(false);
    setMsgIds([]);
    const { data } = await callAPI(`/message/delete`, {}, "POST", {
      id: msgIds,
      group: groupId,
    });
  };

  const getPreserveChatHistory = () => {
    let getChatHistories = localStorage.getItem("chatHistories")
      ? JSON.parse(localStorage.getItem("chatHistories"))
      : [];

    console.log(getChatHistories);
    const foundIndex = getChatHistories.findIndex(
      (msg) => msg.userId === user?.id && msg.group === props.chat.id
    );
    console.log(foundIndex);
    if (foundIndex > -1) {
      console.log(getChatHistories[foundIndex]);
      setChatHistory(getChatHistories[foundIndex].messages);
    }
  };

  useEffect(() => {
    getDetails(props.chat.id);
    // getPreserveChatHistory();
  }, [props.chat.id]);

  useEffect(() => {
    if (NewMess) {
      handler(NewMess);
    }
  }, [NewMess]);

  useEffect(() => {
    if (isDeli) {
      _deliverMess(isDeli);
    }
  }, [isDeli]);

  useEffect(() => {
    if (isRead) {
      _readMess(isRead);
    }
  }, [isRead]);

  useEffect(() => {
    if (makeOnOff) {
      _makeStatusOnlineOffline(makeOnOff);
    }
  }, [makeOnOff]);

  useEffect(() => {
    if (emojiMessage) {
      if (!emojiMessage.isEmojiSet) {
        const newChat = tempChatHistory?.[0]?.data?.map((chatGroup, index) => {
          return {
            ...chatGroup,
            items: chatGroup?.items?.map((chat) => {
              if (chat?.id === emojiMessage?.chat) {
                const newEmojiList = [...chat?.userEmoji];
                const emojiInd = newEmojiList?.findIndex(
                  (emoji) =>
                    emoji?.sender === emojiMessage?.sender &&
                    emojiMessage?.emoji === emoji?.emoji
                );
                if (emojiInd !== -1) {
                  newEmojiList?.splice(emojiInd, 1);
                }
                return {
                  ...chat,
                  userEmoji: newEmojiList,
                };
              } else {
                return chat;
              }
            }),
          };
        });
        const newTempChat = [{ id: tempChatHistory?.[0]?.id, data: newChat }];
        setTempChatHistory(newTempChat);
        setEmojiMessage(null);
      } else {
        const emojiInfo = {
          sender: emojiMessage?.sender,
          emoji: emojiMessage?.emoji,
        };

        const newChat = tempChatHistory?.[0]?.data?.map((chatGroup, index) => {
          return {
            ...chatGroup,
            items: chatGroup?.items?.map((chat) => {
              if (chat?.id === emojiMessage?.chat) {
                const emojiInd = chat?.userEmoji?.findIndex(
                  (emoji) =>
                    emoji?.sender === emojiMessage?.sender &&
                    emojiMessage?.emoji === emoji?.emoji
                );
                return {
                  ...chat,
                  userEmoji:
                    emojiInd !== -1
                      ? [...chat?.userEmoji]
                      : chat?.userEmoji?.length
                        ? [...chat?.userEmoji, emojiInfo]
                        : [emojiInfo],
                };
              } else {
                return chat;
              }
            }),
          };
        });

        const newTempChat = [{ id: tempChatHistory?.[0]?.id, data: newChat }];
        setTempChatHistory(newTempChat);
        setEmojiMessage(null);
      }
    }
  }, [emojiMessage]);

  const handler = async (data) => {
    let tx = [...tempChatHistory];
    if (data.groupId == tx[0].id) {
      if (data && data.senderId == user.id) {
        let tx = [...tempChatHistory];
        let fillt = tx[0].data.map((e, i) => {
          let itts = e.items.map((l, j) => {
            if (data.messageRequestId == l.messageRequestId) {
              return { ...l, ...data, local: false };
            } else {
              return { ...l };
            }
          });
          return { ...e, items: itts };
        });
        tx[0].data = [...fillt];
        setTempChatHistory([...tx]);
        setNewMess(null);
      } else {
        let tx = [...tempChatHistory];
        tx[0].data[0].items.push(data);
        setTempChatHistory([...tx]);
        setNewMess(null);
      }
      setNewMess(false);
    }
  };

  const _readMess = (data) => {
    let tx = tempChatHistory.length > 0 ? tempChatHistory[0] : {};
    if (tx.data) {
      let fillt = tx.data.map((e, i) => {
        let itts = e.items.map((l, j) => {
          let ind = data.chats.findIndex((e) => e.id == l.id);
          if (ind !== -1) {
            return {
              ...l,
              messageReadByAll:
                data.chats[ind].messageReadByAll == "true" ||
                  data.chats[ind].isRead
                  ? true
                  : false,
            };
          } else {
            return { ...l };
          }
        });
        return { ...e, items: itts };
      });
      let txx = [...tempChatHistory];
      txx[0] = { ...txx[0], data: [...fillt] };
      setTempChatHistory([...txx]);
      setIsRead(null);
    }
  };

  const _deliverMess = (data) => {
    let tx = tempChatHistory.length > 0 ? tempChatHistory[0] : {};
    console.log("tx", tx);
    if (tx.data) {
      let fillt = tx.data.map((e, i) => {
        let itts = e.items.map((l, j) => {
          let ind = data.chats.findIndex((e) => e.id == l.id);
          if (ind !== -1) {
            return {
              ...l,
              messageReachedToAll:
                data.chats[ind].messageReachedToAll == "true" ? true : false,
            };
          } else {
            return { ...l };
          }
        });
        return { ...e, items: itts };
      });
      let txx = [...tempChatHistory];
      txx[0] = { ...txx[0], data: [...fillt] };
      setTempChatHistory([...txx]);
      setIsDeli(null);
    }
  };

  const _makeStatusOnlineOffline = (data) => {
    if (chatDetails.id === data.user) {
      setChatDetails((prev) => {
        return {
          ...prev,
          isOnline: data.isOnline,
        };
      });
    }
    setMakeOnOff(false);
  };

  const _registerEVents = async () => {
    if (Socket && Socket.on) {
      try {
        Socket.off(props.chat.id).on(props.chat.id, function (data) {
          setTypingUser(data.typingData[0]);
          clearTypingUser();
        });

        Socket.off("userStatus").on("userStatus", function (data) {
          setMakeOnOff(data);
        });
        Socket.off("isRead").on("isRead", async function (data) {
          setTimeout(() => {
            setIsRead(data);
          }, 3500);
        });
        Socket.off("isSend").on("isSend", async function (data) {
          setTimeout(() => {
            setIsDeli(data);
          }, 2000);
        });

        Socket.off("created").on("created", async function (data) {
          console.log("created", data);
          setNewMess(data.message);
        });

        Socket.off("emoji").on("emoji", async function (data) {
          setEmojiMessage(data);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const disConnect = () => {
    Socket?.emit("get", groupUnsubscribeObj, async (data) => {
      console.log("Unsubscribed Successfully", data);
    });
  };

  const clearDraftMessage = async (chatId) => {
    let allMessages = props.draftMessages;
    allMessages = await allMessages.filter((msg) => msg.group !== chatId);
    localStorage.setItem("draftMessage", JSON.stringify(allMessages));
    props.updateDraftMessages(allMessages);
  };

  const preserveChatHistory = (chatId) => {
    const chatHistoryObj = {
      userId: user?.id,
      group: chatId,
      messages: chatHistory,
    };
    console.log(chatHistoryObj);
    let chatHistories = localStorage.getItem("chatHistories")
      ? JSON.parse(localStorage.getItem("chatHistories"))
      : [];
    const foundIndex = chatHistories.findIndex(
      (msg) => msg.userId === user?.id && msg.group === chatId
    );
    console.log(foundIndex);
    if (foundIndex > -1) {
      console.log(chatHistories[foundIndex]);
      chatHistories[foundIndex] = {
        ...chatHistories[foundIndex],
        messages: chatHistory,
      };
    } else {
      chatHistories = [...chatHistories, chatHistoryObj];
    }
    console.log(chatHistories);
    localStorage.setItem("chatHistories", JSON.stringify(chatHistories));
  };

  useEffect(() => {
    return () => {
      disConnect();
    };
  }, []);

  const hideProject = async (chatId) => {
    //latest 100 messages per chat
    let tmp = [...activityMessages];
    tmp = tmp.map((e) => {
      return { ...e, isOpened: e.group.id == chatId ? false : e.isOpened };
    });
    let tmp1 = [...activityMessagesReminders];
    tmp1 = tmp1.map((e) => {
      return { ...e, isOpened: e.group.id == chatId ? false : e.isOpened };
    });
    dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
    dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp1 });
    preserveChatHistory(chatId);
    setDeleteId(chatId);
    if (message) {
      const msgObject = {
        userId: user?.id,
        message: message,
        messageType: "simple",
        group: chatId,
      };

      let allMessages = props.draftMessages;
      const foundIndex = allMessages.findIndex(
        (msg) => msg.userId === user?.id && msg.group === chatId
      );
      console.log(foundIndex);
      if (foundIndex > -1) {
        console.log(allMessages[foundIndex]);
        allMessages[foundIndex] = {
          ...allMessages[foundIndex],
          message: message,
        };
      } else {
        allMessages = [...allMessages, msgObject];
      }
      console.log(allMessages);
      localStorage.setItem("draftMessage", JSON.stringify(allMessages));
      props.updateDraftMessages(allMessages);
    }

    if (searchView) {
      setSearchView(false);
    } else {
      setVisible(false);
      setTimeout(async () => {
        const id = chatId;
        const { openChats } = props;
        const oldData = await openChats.filter((p) => p.id !== id);
        const updateGroups = props.groups.map((g) => {
          if (g.id === id) {
            return { ...g, isOpened: false };
          } else {
            return { ...g };
          }
        });
        await props.updateGroups(updateGroups);
        props.updateOpenChats(oldData);
      }, 900);
    }
  };

  useEffect(() => {
    const msg = props.draftMessages.find(
      (m) => m.userId === user?.id && m.group === props.chat.id
    );
    if (msg) {
      $(`.textInput-${props.chat.id}`).text(msg.message);
      setMessage(msg.message);
    }
    // Socket?.emit("get", connectObj, async (data) => {
    //   _registerEVents()
    //   await groupByDate(data.body.chatHistory, props.chat.id);
    // });
    _getMessages();
  }, [props.chat.id]);

  const _getMessages = async () => {
    setIsGettingChats(true);
    const { data } = await callAPI(
      `/group/${props.chat.id}/getChat?page=${page}&limit=20`,
      {},
      "GET"
    );
    if (data.status) {
      _registerEVents();
      // data.chatHistory = data.chatHistory.map((e) => {
      //   console.log(e)
      //   return { ...e, message: e.message ? he.encode(e.message) : e.message }
      // })
      await groupByDate(data.chatHistory, props.chat.id);
      setIsGettingChats(false);
    } else {
      setIsGettingChats(false);
    }
  };

  const _getMessagesBottomReached = async (pageno) => {
    setIsGettingChats(true);
    const { data } = await callAPI(
      `/group/${props.chat.id}/getChat?page=${pageno}&limit=20`,
      {},
      "GET"
    );
    if (data.status) {
      if (data.chatEnd) {
        setHasMore(false);
      }
      // data.chatHistory = data.chatHistory.map((e) => {
      //   console.log(e)
      //   return { ...e, message: e.message ? he.encode(e.message) : e.message }
      // })
      groupByDate(data.chatHistory, props.chat.id);
      setIsGettingChats(false);
    } else {
      setIsGettingChats(false);
    }
  };

  useEffect(() => {
    if (Socket && Socket.on) {
      _subsOnly();
    }
  }, [Socket]);

  const _subsOnly = async () => {
    let x = {
      url: `${API_ROOT}/group/${props.chat.id}/subscribe`,
      method: "get",
      headers: {
        Authorization: `jwt ${$user.getToken()}`,
      },
    };
    await Socket?.emit("get", x, async ({ body }) => {
      if (body?.groupInfo?.secretKey) {
        setSecretKey(body?.groupInfo?.secretKey || null);
      }
    });
  };

  const clearTypingUser = () => {
    setTimeout(() => {
      setTypingUser({});
    }, 3000);
  };
  // console.log("getChatI===>", getChatId, "chatHost", chatHistory)
  const sendMessage = async (chatId) => {
    textRef.current.style.height = "30px";
    scrollToBottom(chatId);
    let messagex = message;
    setMessage("");
    setTextareaHeight(true);
    let converText = messagex
      .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
      .replace(/<[^>]+>/g, "")
      .replace(/&nbsp;/g, " ");
    // converText = messagex?.replace(/\n/g, "<br/>");
    setChatId(chatId);
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    } else {
      if (messagex) {
        let uniID = makeid(10);
        if (!activeReply) {
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: secretKey
              ? encryptMessage(messagex.trim(), secretKey)
              : messagex.trim(),
            messageLng: "en",
            messageType: "simple",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            isSent: true,
            // Rid: chatMessageInfo.id,
            // inReplyTo: { ...chatMessageInfo }
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          $(`.textInput-${chatId}`).val("");
          console.log("====>", converText);
          const { data } = await callAPI("/message", {}, "POST", {
            group: chatId,
            isGroup: true,
            message: secretKey
              ? encryptMessage(converText, secretKey)
              : converText,
            messageType: "simple",
            sender: user?.id,
            messageRequestId: uniID,
          });
          clearDraftMessage(chatId);
        } else {
          let ttx = replyMessage;
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: secretKey
              ? encryptMessage(messagex.trim(), secretKey)
              : messagex.trim(),
            messageLng: "en",
            messageType: "simple",
            senderId: user.id,
            userEmoji: [],
            isReply: true,
            messageRequestId: uniID,
            isSent: true,
            Rid: ttx.id,
            inReplyTo: {
              ...ttx,
              message: secretKey
                ? encryptMessage(ttx?.message, secretKey)
                : ttx?.message,
            },
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          setActiveReply(false);
          setMessage("");
          setActiveReply(false);
          setReplyMessage(null);
          clearDraftMessage(chatId);
          $(`.textInput-${chatId}`).val("");
          const { data } = await callAPI(
            `/message/${ttx.id}/reply`,
            {},
            "POST",
            {
              id: ttx.id,
              group: chatId,
              isGroup: true,
              message: secretKey
                ? encryptMessage(converText, secretKey)
                : converText,
              messageType: "simple",
              sender: user?.id,
              messageRequestId: uniID,
            }
          );
        }
      } else {
        console.log("Please enter message");
      }
    }
    setTextareaHeight(false);
  };

  const sendImageMessage = async (message, image, imageDimension, chatId) => {
    setMessage("");
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    } else {
      if (!activeReply) {
        let x = [];
        image?.forEach(async (e, i) => {
          console.log(e);
          x.push({
            e,
            isLastImage:
              i == image.length - 1 && image.length > 1 ? true : false,
          });
        });
        let tt = [];
        tt.push({ data: [{ items: [] }], id: props.chat?.id });
        x?.forEach(async (img, index) => {
          let uniID = makeid(10);
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: secretKey
              ? encryptMessage(message.trim(), secretKey)
              : message.trim(),
            messageLng: "en",
            messageType: "image",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            isSent: true,
            fileUrl: [(!img.e.type || (img.e.type === 'image/heic' || img.e.name.endsWith('.heic'))) ? process.env.PUBLIC_URL + (`assets/img/${isGerm ? 'no-image-preview-de.png' : 'no-image-preview-en.png'}`) :img.e.url],
            local: true,
            hasMultiple: image.length > 1 ? true : false,
            isLastImage: img.isLastImage,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            tt[0].data[0].items.push(msg);
          }
          let formData = new FormData();
          formData.append("group", `${chatId}`);
          formData.append("isGroup", "true");
          formData.append(
            "message",
            `${secretKey ? encryptMessage(message, secretKey) : message}`
          );
          formData.append("messageType", "image");
          formData.append("sender", `${user?.id}`);
          formData.append("messageRequestId", uniID);
          if(imageDimension[index]?.height)
          formData.append("height", imageDimension[index]?.height);
          formData.append("attachedFileName", img.e.name);
          if(imageDimension[index]?.width)
          formData.append("width", imageDimension[index]?.width);
          formData.append("file", img.e);
          console.log(formData);
          await callAPI2("/message", {}, "POST", formData);
        });
        if (tempChatHistory.length == 0) {
          setTempChatHistory([...tt]);
        }
        clearDraftMessage(chatId);
      } else {
        let ttx = replyMessage;
        let x = [];
        image?.forEach(async (e, i) => {
          console.log(e);
          x.push({
            e,
            isLastImage:
              i == image.length - 1 && image.length > 1 ? true : false,
          });
        });
        let tt = [];
        tt.push({ data: [{ items: [] }], id: props.chat?.id });
        x?.forEach(async (img, index) => {
          let uniID = makeid(10);
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: secretKey
              ? encryptMessage(message.trim(), secretKey)
              : message.trim(),
            messageLng: "en",
            messageType: "image",
            senderId: user.id,
            userEmoji: [],
            isReply: true,
            messageRequestId: uniID,
            isSent: true,
            Rid: ttx.id,
            inReplyTo: {
              ...ttx,
              fileUrl: [(!img.e.type || (img.e.type === 'image/heic' || img.e.name.endsWith('.heic'))) ? process.env.PUBLIC_URL + (`assets/img/${isGerm ? 'no-image-preview-de.png' : 'no-image-preview-en.png'}`) :img.e.url],
              message: secretKey
                ? encryptMessage(ttx?.message, secretKey)
                : ttx?.message,
            },
            fileUrl: [(!img.e.type || (img.e.type === 'image/heic' || img.e.name.endsWith('.heic'))) ? process.env.PUBLIC_URL + (`assets/img/${isGerm ? 'no-image-preview-de.png' : 'no-image-preview-en.png'}`) :img.e.url],
            local: true,
            hasMultiple: image.length > 1 ? true : false,
            isLastImage: img.isLastImage,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            tt[0].data[0].items.push(msg);
          }
          setActiveReply(false);
          let formData = new FormData();
          formData.append("id", replyMessage.id);
          formData.append("group", chatId);
          formData.append("isGroup", true);
          formData.append(
            "message",
            `${secretKey ? encryptMessage(message, secretKey) : message}`
          );
          formData.append("messageType", "image");
          formData.append("sender", user?.id);
          formData.append("messageRequestId", uniID);
          if(imageDimension[index]?.height)
          formData.append("height", imageDimension[index]?.height);
          formData.append("attachedFileName", img.e.name);
          if(imageDimension[index]?.width)
          formData.append("width", imageDimension[index]?.width);
          formData.append("file", img.e);
          await callAPI2(
            `/message/${replyMessage.id}/reply`,
            {},
            "POST",
            formData
          );
        });
        if (tempChatHistory.length == 0) {
          setTempChatHistory([...tt]);
        }
        clearDraftMessage(chatId);
        setMessage("");
        setActiveReply(false);
        setReplyMessage(null);
      }
    }
  };
  const sendDocFile = async (doc, fileName, chatId, base64) => {
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    } else {
      if (doc) {
        let uniID = makeid(10);
        if (!activeReply) {
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "doc",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            isSent: true,
            fileUrl: [base64],
            local: true,
            attachedFileName: fileName,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          let formData = new FormData();
          formData.append("group", chatId);
          formData.append("isGroup", "true");
          formData.append("message", "");
          formData.append("messageType", "doc");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("attachedFileName", `${fileName}`);
          formData.append("file", doc);
          console.log(doc);
          const { data } = await callAPI2("/message", {}, "POST", formData);
          clearDraftMessage(chatId);
        } else {
          let ttx = replyMessage;
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "image",
            senderId: user.id,
            userEmoji: [],
            isReply: true,
            messageRequestId: uniID,
            isSent: true,
            Rid: ttx.id,
            inReplyTo: { ...ttx, fileUrl: [base64] },
            fileUrl: [base64],
            local: true,
            attachedFileName: fileName,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          setActiveReply(false);
          let formData = new FormData();
          formData.append("id", replyMessage.id);
          formData.append("group", chatId);
          formData.append("isGroup", "true");
          formData.append("message", "");
          formData.append("messageType", "doc");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("attachedFileName", `${fileName}`);
          formData.append("file", doc);
          const { data } = await callAPI2(
            `/message/${replyMessage.id}/reply`,
            {},
            "POST",
            formData
          );
          clearDraftMessage(chatId);
          setActiveReply(false);
          setReplyMessage(null);
        }
      } else {
        console.log("Please enter message");
      }
    }
  };
  const sendVideoMessage = async (video, message, chatId) => {
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      setRows(1);
      getDetails(chatId);
    } else {
      if (video) {
        let uniID = makeid(10);
        if (!activeReply) {
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "video",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            isSent: true,
            fileUrl: [video.url],
            local: true,
            attachedFileName: video.name,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          let formData = new FormData();
          formData.append("group", chatId);
          formData.append("isGroup", "true");
          formData.append("message", `${message}`);
          formData.append("messageType", "video");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("file", video, video.name);
          formData.append("attachedFileName", video.name);
          const { data } = await callAPI2("/message", {}, "POST", formData);
          clearDraftMessage(chatId);
        } else {
          setActiveReply(false);
          let formData = new FormData();
          formData.append("id", replyMessage.id);
          formData.append("group", chatId);
          formData.append("isGroup", "true");
          formData.append("message", `${message}`);
          formData.append("messageType", "video");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("file", video, video.name);
          formData.append("attachedFileName", video.name);
          const { data } = await callAPI2(
            `/message/${replyMessage.id}/reply`,
            {},
            "POST",
            formData
          );
          clearDraftMessage(chatId);
          setActiveReply(false);
          setReplyMessage(null);
        }
      } else {
        console.log("Please add Video");
      }
    }
  };
  const sendMapMessage = async (message, chatId) => {
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    } else {
      if (message) {
        let uniID = makeid(10);
        if (!activeReply) {
          let ttx = replyMessage;
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "map",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            local: true,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          let formData = new FormData();
          formData.append("group", chatId);
          formData.append("isGroup", true);
          formData.append("message", message);
          formData.append("messageRequestId", uniID);
          formData.append("messageType", "map");
          formData.append("sender", user?.id);
          const { data } = await callAPI2("/message", {}, "POST", formData);
          clearDraftMessage(chatId);
        } else {
          let ttx = replyMessage;
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "map",
            senderId: user.id,
            userEmoji: [],
            isReply: true,
            messageRequestId: uniID,
            isSent: true,
            local: true,
            Rid: ttx.id,
            inReplyTo: { ...ttx },
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          setActiveReply(false);
          let formData = new FormData();
          formData.append("id", replyMessage.id);
          formData.append("group", chatId);
          formData.append("isGroup", true);
          formData.append("message", message);
          formData.append("messageRequestId", uniID);
          formData.append("messageType", "map");
          formData.append("sender", user?.id);
          const { data } = await callAPI2(
            `/message/${replyMessage.id}/reply`,
            {},
            "POST",
            formData
          );
          clearDraftMessage(chatId);
          setActiveReply(false);
          setReplyMessage(null);
        }
      } else {
        console.log("Please set map link");
      }
    }
  };

  const scrollToBottom = (chatId) => {
    let chtDiv = document.getElementById(`chat-sc-${chatId}`);
    chtDiv?.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const userTyping = (e, chatId, emoji) => {
    if (e) {
      e.target.style.height = "34px";
      e.target.style.height = e.target.scrollHeight + "px";
      // setMessage(e.currentTarget.textContent);
      setMessage(e.currentTarget.value);
      const id = chatId;
      const axiosReqVal = $.ajax({
        url: `${API_ROOT}/group/${id}/checkIsTyping`,
        type: "POST",
        dataType: "json",
        headers: {
          Accept: "application/json",
          Authorization: `jwt ${$user.getToken()}`,
        },
        data: {
          isTyping: true,
        },
        beforeSend: function () {
          if (axiosReq !== null) {
            axiosReq.abort();
          }
        },
        success: function (res) {
          setAxiosReq(null);
        },
      });
      setAxiosReq(axiosReqVal);
    } else {
      const textAreaElement = document.getElementById(
        `text-area-${props.chat.id}`
      );
      resetCursor(textAreaElement.selectionStart, textAreaElement.selectionEnd);
      setMessage(
        message.substr(0, textAreaElement.selectionStart) +
        emoji +
        message.substr(textAreaElement.selectionEnd)
      );
    }
  };

  function resetCursor(x, y) {
    let txtElement = textRef.current;
    if (txtElement.setSelectionRange) {
      txtElement.focus();
      txtElement.setSelectionRange(x, y);
    } else if (txtElement.createTextRange) {
      var range = txtElement.createTextRange();
      range.moveStart("character", 0);
      range.select();
    }
  }

  const handleKeyDown = async (e, chatId) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (chatDetails.isBlock) {
        await $crud.confirm({
          title: "Unblock user to send message",
          textContent: "",
          options: {
            ok: "Unblock",
            cancel: "Cancel",
          },
        });
        const id = chatId;
        const user = chatDetails.id;
        const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
          id: id,
          user: user,
        });
        getDetails(chatId);
      } else {
        if (e.keyCode === 13) {
          sendMessage(chatId);
        }
      }
    }
  };

  const handleSearch = async (e, chatId) => {
    setSearch(e.target.value);
    let text = e.target.value;
    console.log(text);
    setTimeout(() => {
      const para = document.getElementsByClassName(`simple-msg-${chatId}`);
      let highCount = 0;
      let hIds = [];
      if (text !== "") {
        let ind = 0;
        _.forEach(para, function (p) {
          const sp = p.getElementsByTagName("span");
          let innText = sp[0].innerText;
          if (innText.toLowerCase().includes(text.toLowerCase())) {
            highCount = highCount + 1;
            let match = innText.toLowerCase().indexOf(text.toLowerCase());
            const hId = `span${ind}`;
            hIds.push(hId);
            innText =
              innText.substring(0, match) +
              `<span style='background-color: yellow;float:none'  id='${"span" + ind
              }'>` +
              innText.substring(match, match + text.length) +
              "</span>" +
              innText.substring(match + text.length);
            sp[0].innerHTML = innText;
            ind++;
          } else {
            sp[0].innerHTML = sp[0].innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
          }
        });
        unstable_batchedUpdates(() => {
          setCurrentIndex(highCount > 0 ? 1 : 0);
          setHighlightsIds(hIds);
          setTotalHighlights(highCount);
        });
      } else {
        _.forEach(para, function (p) {
          const sp = p.getElementsByTagName("span");
          sp[0].innerHTML = sp[0].innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
        });
        setTotalHighlights(0);
      }
    }, 100);
    scrollToBottom(chatId);
  };

  const highlight = async (index) => {
    if (totalHighlights && index < totalHighlights && index >= 0) {
      console.log(`#span${index}`);
      const parentNode = document.querySelector(`#span${index}`).parentNode
        .parentNode.parentNode.parentNode;
      document.getElementById(`${parentNode?.id}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      // document.getElementById(`chat-scroll-view-${props.chat.id}`).scrollIntoView()
      // .scrollTo({
      //   top:
      //     parentNode.offsetTop -
      //     document
      //       .getElementById(`chat-scroll-view-${props.chat.id}`)
      //       .getBoundingClientRect().top -
      //     40,
      //   behavior: "smooth",
      // });
      parentNode.className += " highlight";
      const t = setTimeout(() => {
        parentNode.className = parentNode.className
          ?.split(" ")
          .filter((a) => a !== "highlight")
          .join(" ");
        clearTimeout(t);
      }, 2000);
    }
  };

  const nextHighlight = async () => {
    if (searchIndex < totalHighlights - 1) {
      console.log("searchIndex-<next", searchIndex);
      setSearchIndex(searchIndex + 1);
      await highlight(searchIndex + 1);
    }
  };

  const prevHighlight = async () => {
    if (searchIndex > 0) {
      console.log("searchIndex-<prev", searchIndex);
      setSearchIndex(searchIndex - 1);
      highlight(searchIndex - 1);
    }
  };

  useEffect(() => {
    setSearchIndex(totalHighlights - 1);
    highlight(totalHighlights - 1);
  }, [totalHighlights]);

  const handleMessageInfo = async (msg) => {
    if (secretKey && (msg?.messageType === "simple" || msg?.messageType === "image")) {
      const updateChat = {
        ...msg,
        inReplyTo: msg.inReplyTo ? { ...msg.inReplyTo, message: msg.inReplyTo.message ? secretKey ? decryptMessage(msg.inReplyTo.message, secretKey) : msg.inReplyTo.message : msg.inReplyTo.message } : false,
        message: secretKey ? decryptMessage(msg.message, secretKey) : msg.message,
      };
      await setChatMessage(updateChat);
      setMessageInfo(true);
    }
    else {
      await setChatMessage(msg);
      setMessageInfo(true);
    }
  };

  const toggleBlockUser = async (chatId) => {
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    }
  };

  const handleForward = () => {
    setForward(true);
  };
  const handleCopy = (chat) => {
    const copyMsg = secretKey
      ? decryptMessage(chat?.message, secretKey)
      : chat?.message;
    let ctt = copyMsg
      .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
      .replace(/<[^>]+>/g, "")
      .replace(/&nbsp;/g, " ");
    navigator.clipboard.writeText(ctt).then((r) => {
      $crud.notify({
        type: "success",
        message: "Copied!",
      });
    });
  };
  const handleCopyImage = async (chat) => {
    let imageUrl = chat.local ? chat?.fileUrl[0] : BASE_URL + chat?.fileUrl[0]
    try {
      // Fetch the image as a Blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      if (blob.type != 'image/png') {
        // Convert the image/jpeg Blob to a PNG Blob
        const img = document.createElement('img');
        img.src = URL.createObjectURL(blob);

        img.onload = async () => {
          // Create a canvas to convert the image to PNG
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set canvas size to image size
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0);

          // Convert the canvas content to a PNG Blob
          canvas.toBlob(async (pngBlob) => {
            try {
              // Copy the PNG Blob using copyBlobToClipboard
              await copyBlobToClipboard(pngBlob);
              $crud.notify({
                type: "success",
                message: "Copied!",
              });
              // alert('Image copied to clipboard as PNG!');
            } catch (err) {
              console.error('Failed to copy image: ', err);
            }
          }, 'image/png');
        };
      } else {
        // If the blob is already a supported format (e.g., PNG), copy it directly
        await copyBlobToClipboard(blob);
        $crud.notify({
          type: "success",
          message: "Copied!",
        });
        // alert('Image copied to clipboard!');
      }
    } catch (err) {
      console.error('Failed to copy image: ', err);
    }
  };
  const currentTime = moment();
  const selectIds = (chat) => {
    const id = chat.id;
    const chk = msgIds.find((msg) => msg === id);
    if (!chk) {
      const ids = [...msgIds, id];
      console.log(ids);
      setMsgIds(ids);
    } else {
      const ids = msgIds.filter((msg) => msg !== id);
      setMsgIds(ids);
    }
  };
  const handleForwardMessage = async () => {
    const msgCount = msgIds.length;
    if (msgCount > 0) {
      setForward(false);
      props.updateForwardMessage(true);
      props.updateMessageIds(msgIds);
      props.updateForOpenChatId(props.chat.id);
    } else {
      $crud.notify({
        type: "error",
        message: "Please select message to forward",
      });
    }
  };

  const cancelForward = () => {
    if (forward) {
      setForward(false);
    } else {
      setDelMessage(false);
    }
    setMsgIds([]);
  };
  const handleReply = (chat) => {
    setActiveReply(true);
    if (
      secretKey &&
      (chat?.messageType === "simple" || chat?.messageType === "image")
    ) {
      const updateChat = {
        ...chat,
        message: secretKey
          ? decryptMessage(chat.message, secretKey)
          : chat.message,
      };
      setReplyMessage(updateChat);
      return;
    }
    setReplyMessage(chat);
  };

  const cancelReply = () => {
    setActiveReply(false);
    setReplyMessage(null);
  };

  function handleAnchorRef(id, chatId) {
    const parentNode = document.getElementById(id);
    console.log(id);
    console.log(parentNode);
    document.getElementById(`chat-scroll-view-${chatId}`).scrollTo({
      top:
        parentNode.offsetTop -
        document
          .getElementById(`chat-scroll-view-${chatId}`)
          .getBoundingClientRect().top -
        40,
      behavior: "smooth",
    });
    parentNode.className += " highlight";
    const t = setTimeout(() => {
      parentNode.className = parentNode.className
        ?.split(" ")
        .filter((a) => a !== "highlight")
        .join(" ");
      clearTimeout(t);
    }, 2000);
  }

  const start = () => {
    if (navigator.getUserMedia || navigator.webkitGetUserMedia) {
      setAudioRecording(true);
      setRecordState(RecordState.START);
      console.log(recordState);
    } else {
      $crud.notify({
        type: "error",
        message: "Microphone is not connected with your system",
      });
    }
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
    console.log(recordState);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //audioData contains blob and blobUrl
  const stopRecording = async (audioData, chatId) => {
    console.log(RecordState);
    console.log("audioData", audioData);
    if (audioData && audioRecording) {
      const $fileName = new Date().getTime() + ".mp3";
      const base64 = await convertBase64(audioData.blob);
      const audioObj = new File([audioData.blob], $fileName, {
        lastModified: new Date().getTime(),
        type: "audio/mp3",
      });
      console.log("audioData", audioData);
      console.log("file", audioObj.name);
      sendAudioFile(audioObj, chatId, base64);
      setAudioRecording(false);
    }
  };
  const sendAudioFile = async (audio, chatId, base64) => {
    console.log(audio);
    if (chatDetails.isBlock) {
      await $crud.confirm({
        title: "Unblock user to send message",
        textContent: "",
        options: {
          ok: "Unblock",
          cancel: "Cancel",
        },
      });
      const id = chatId;
      const user = chatDetails.id;
      const { data } = await callAPI(`/group/${id}/block`, {}, "PUT", {
        id: id,
        user: user,
      });
      getDetails(chatId);
    } else {
      if (audio) {
        let uniID = makeid(10);
        if (!activeReply) {
          // let ttx = replyMessage
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "audio",
            senderId: user.id,
            userEmoji: [],
            isReply: false,
            messageRequestId: uniID,
            isSent: true,
            fileUrl: [base64],
            local: true,
            attachedFileName: audio.name,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          let formData = new FormData();
          formData.append("group", `${chatId}`);
          formData.append("isGroup", "true");
          formData.append("message", `${message}`);
          formData.append("messageType", "audio");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("attachedFileName", `${audio.name}`);
          formData.append("file", audio, audio.name);

          const { data } = await callAPI2("/message", {}, "POST", formData);
        } else {
          let ttx = replyMessage;
          let msg = {
            createdAt: new Date(),
            id: uniID,
            isSend: false,
            message: message.trim(),
            messageLng: "en",
            messageType: "audio",
            senderId: user.id,
            userEmoji: [],
            isReply: true,
            messageRequestId: uniID,
            isSent: true,
            Rid: ttx.id,
            inReplyTo: { ...ttx, fileUrl: [audio.url] },
            fileUrl: [audio.url],
            local: true,
            attachedFileName: audio.name,
          };
          if (tempChatHistory.length > 0) {
            let tt = [...tempChatHistory];
            tt[0].data[0].items.push(msg);
            setTempChatHistory([...tt]);
          } else {
            let data = [{ items: [{ ...msg }] }];
            let tt = [];
            tt.push({ data: data, id: props.chat.id });
            setTempChatHistory([...tt]);
          }
          setActiveReply(false);
          let formData = new FormData();
          formData.append("id", replyMessage.id);
          formData.append("group", `${chatId}`);
          formData.append("isGroup", "true");
          formData.append("message", `${message}`);
          formData.append("messageType", "audio");
          formData.append("messageRequestId", uniID);
          formData.append("sender", `${user?.id}`);
          formData.append("attachedFileName", `${audio.name}`);
          formData.append("file", audio, audio.name);
          const { data } = await callAPI2(
            `/message/${replyMessage.id}/reply`,
            {},
            "POST",
            formData
          );
          setActiveReply(false);
          setReplyMessage(null);
        }
      } else {
        console.log("Please enter message");
      }
    }
  };

  useEffect(() => {
    let interval;
    if (audioRecording) {
      interval = setInterval(() => {
        setRecordingCounter(recordingCounter + 1);
      }, 1000);
    }

    if (!audioRecording) {
      setRecordingCounter(0);
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [recordingCounter, audioRecording]);

  const setText = (e) => {
    const htmlData = e.target.innerHTML;
    const plainText = htmlData.replace(/<\/?[^>]+>/gi, " ");
    e.target.innerHTML = plainText;
    setMessage(e.target.value);
  };

  const setFocus = (e) => {
    e.target.focus();
  };

  const handleCloseViewDoc = (chatId) => {
    setViewDoc(false);
    setTimeout(() => {
      scrollToBottom(chatId);
    }, 500);
    clearInterval();
  };

  const handleEmojiPicker = () => {
    setEmojiPicker(!openEmojiPicker);
  };
  const fetchMoreData = (chatId) => {
    if (!isGettingChats) {
      let finalPage = page + 1;
      let filterObj = { ...connectObj };
      filterObj.url = `${API_ROOT}/group/${chatId}/getChat?page=${finalPage}&limit=20`;
      setConnectObj(filterObj);
      setPage((page) => page + 1);
      _getMessagesBottomReached(finalPage);
    }
  };

  const _sendContact = async (data) => {
    for (let obj of data.conts) {
      let uniID = makeid(10);
      let dt = { ...data, ...obj, messageRequestId: uniID, contacts: [obj.id] };
      let dt1 = {
        ...data,
        ...obj,
        messageRequestId: uniID,
        contacts: [{ ...obj }],
      };
      let tt = [...tempChatHistory];
      tt[0].data[0].items.push(dt1);
      setTempChatHistory([...tt]);
      $(`.textInput-${props.chat.id}`).val("");
      await callAPI("/message", {}, "POST", dt);
    }
  };

  const _renderImages = (chatu) => {
    let xChat = { ...chatu };
    let tt = [];
    if (xChat.images && xChat.images.length > 1) {
      let tmp = [...xChat.images];
      tt = tmp.splice(0, 4);
    }
    return xChat.images && xChat.images.length > 1 ? (
      <div>
        {xChat.images?.length > 4 ? (
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                display: "flex",
              }}
            >
              {tt.map((chat, i) => {
                return (
                  <img
                    onClick={() => {
                      dispatch({ type: SET_VIEWER_IINITAL_INDEX, index: i })
                      dispatch({
                        type: SET_VIEWER_IMAGES,
                        ViewerImages: xChat.images,
                      });
                    }}
                    style={{
                      objectFit: "cover",
                      height: 120,
                      width: "48%",
                      margin: 2,
                    }}
                    src={
                      xChat.local
                        ? chat?.fileUrl[0]
                        : BASE_URL + chat?.fileUrl[0]
                    }
                    alt=""
                  />
                );
              })}
            </div>
            <div
              onClick={() => {
                dispatch({ type: SET_VIEWER_IINITAL_INDEX, index: 3 })
                dispatch({
                  type: SET_VIEWER_IMAGES,
                  ViewerImages: xChat.images,
                });
              }}
              style={{
                marginRight: 3,
                marginBottom: 1,
                fontWeight: "bold",
                fontSize: "25px",
                height: 120,
                width: "46%",
                backgroundColor: "rgba(0,0,0,0.6)",
                position: "absolute",
                borderRadius: 5,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {"+" + (xChat.images.length - xChat.images.slice(0, 4).length)}
            </div>
          </div>
        ) : (
          <div
            style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}
          >
            {xChat.images.map((chat, i) => {
              return (
                <img
                  // className="w-100"
                  onClick={() => {
                    dispatch({ type: SET_VIEWER_IINITAL_INDEX, index: i })
                    dispatch({
                      type: SET_VIEWER_IMAGES,
                      ViewerImages: xChat.images,
                    });
                  }}
                  style={{
                    objectFit: "cover",
                    height: 120,
                    width: "47%",
                    margin: 2,
                  }}
                  src={
                    xChat.local ? chat?.fileUrl[0] : BASE_URL + chat?.fileUrl[0]
                  }
                  alt=""
                />
              );
            })}
          </div>
        )}
      </div>
    ) : (
      <img
        // className="w-100"
        onClick={() => {
          dispatch({ type: SET_VIEWER_IMAGES, ViewerImages: xChat.images });
        }}
        style={{ objectFit: "cover", height: "100%", width: "100%" }}
        src={xChat.local ? chatu.fileUrl[0] : BASE_URL + chatu.fileUrl[0]}
        alt=""
      />
    );
  };

  const handleEmojiReaction = async (event, chatId) => {
    const emojiInfo = { sender: user?.id, emoji: emojiUnicode(event?.emoji) };

    const newChat = tempChatHistory?.[0]?.data?.map((chatGroup, index) => {
      return {
        ...chatGroup,
        items: chatGroup?.items?.map((chat) =>
          chat?.id === chatId
            ? {
              ...chat,
              userEmoji: chat?.userEmoji?.length
                ? [...chat?.userEmoji, emojiInfo]
                : [emojiInfo],
            }
            : chat
        ),
      };
    });
    const newTempChat = [{ id: tempChatHistory?.[0]?.id, data: newChat }];
    setTempChatHistory(newTempChat);
    const { data } = await callAPI(`/message/${chatId}/emoji`, {}, "POST", {
      id: chatId,
      emoji: emojiUnicode(event?.emoji),
    });
    setEmojiModal({});
  };

  const handleRemoveReaction = async (emojiInfo, chatId) => {
    if (user?.id === emojiInfo?.sender) {
      const newChat = tempChatHistory?.[0]?.data?.map((chatGroup, index) => {
        return {
          ...chatGroup,
          items: chatGroup?.items?.map((chat) => {
            if (chat?.id === chatId) {
              const newEmojiList = [...chat?.userEmoji];
              const emojiInd = newEmojiList.findIndex(
                (emoji) =>
                  emoji?.sender === emojiInfo?.sender &&
                  emojiInfo?.emoji === emoji?.emoji
              );
              if (emojiInd !== -1) {
                newEmojiList?.splice(emojiInd, 1);
              }
              return {
                ...chat,
                userEmoji: newEmojiList,
              };
            } else {
              return chat;
            }
          }),
        };
      });
      const newTempChat = [{ id: tempChatHistory?.[0]?.id, data: newChat }];
      setTempChatHistory(newTempChat);
      const { data } = await callAPI(`/message/${chatId}/emoji`, {}, "POST", {
        id: chatId,
        emoji: emojiInfo?.emoji,
      });
    }
    return;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        // console.log('Clicked outside');

        setEmojiModal({});
        return;
      } else {
        // console.log('Clicked inside');
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handlePaste = (event) => {
    console.log('------>my paste', event)
    const items = event.clipboardData.items;
    const files = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const imageFile = items[i].getAsFile();
        files.push(
          Object.assign(imageFile, {
            url: URL.createObjectURL(imageFile),
          })
        );
      }
    }
    if (files.length > 0) {
      setDropFiles((prevFiles) => [...prevFiles, ...files]);
      setFloatingAction(true);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`classId
        ${deleteId !== props.chat.id ? "close-fix" : "close-fix close-right"}
        `}
        key={props.chat.id}
        id={props.chat.id}
        style={{
          width:
            !viewInfo &&
              !mediaDoc &&
              !showContactSection &&
              !showMessageInfo &&
              !viewDoc
              ? "100%"
              : 0,
          maxWidth: 420,
        }}
      >
        <Animated
          animationIn="slideInLeft"
          animationOut="slideOutLeft"
          animationOutDuration={900}
          isVisible={visible}
        >
          {!viewInfo &&
            !mediaDoc &&
            !showContactSection &&
            !showMessageInfo &&
            !viewDoc && (
              <div
                className="w-300 chat_text"
                style={{
                  height: "93vh",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <div className="chat_header">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      // className="back_number"
                      style={{ marginRight: 10 }}
                    >
                      <a
                        className="pointer"
                        onClick={() => hideProject(props.chat.id)}
                      >
                        <img
                          width={10}
                          src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                          alt="Generic placeholder"
                        />
                      </a>
                    </div>
                    {/* {searchView && (
                          <div className="search-chat">
                            <div className="search m-0">
                              <div className="form-group m-0">
                                <input
                                  placeholder="Search"
                                  type="search"
                                  className="form-control"
                                  value={search}
                                  onChange={(e) =>
                                    handleSearch(e, props.chat.id)
                                  }
                                />
                              </div>
                              <img
                                className="news_t_ser"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/search.svg"
                                }
                                alt="Generic placeholder"
                              />
                            </div>
                          </div>
                        )} */}
                    <div
                      style={{ flex: 1, display: "flex", alignItems: "center" }}
                    >
                      {!searchView && (
                        <div
                          onClick={() => setViewInfo(true)}
                        // className={
                        //   props.chat?.unseenChatCount > 0
                        //     ? "clinet_name_text pointer"
                        //     : "clinet_name_text pointer"
                        // }
                        >
                          {props.chat.name ? (
                            <span style={{ fontWeight: "500" }}>
                              {props.chat.name}
                            </span>
                          ) : (
                            <Skeleton width={200} variant={"text"} />
                          )}
                          {typingUser?.isTyping &&
                            chatDetails.id !== typingUser.user ? (
                            <p>Typing...</p>
                          ) : chatDetails.isOnline ? (
                            <></>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ActiveInactiveLable
                        chatDetails={chatDetails}
                        typingUser={typingUser}
                      />
                    </div>
                  </div>
                </div>
                <Dropzone
                  onDrop={onDrop}
                  children={
                    // <div
                    //   // className="wscroll right_wscroll group-conv-wrapper pl-2"
                    //   style={{flex:1, flexDirection:"column"}}
                    // >
                    <div
                      className={
                        forward
                          ? "bottom-bar chat-scroll forward-msg"
                          : delMessage
                            ? "bottom-bar chat-scroll delete_msg"
                            : sumTotalChatHistory(tempChatHistory) < 4
                              ? "bottom-bar chat-scroll-first"
                              : "bottom-bar chat-scroll"
                      }
                      style={{}}
                      id={`chat-scroll-view-${props.chat.id}`}
                    >
                      <div
                        // className="wscroll right_wscroll group-conv-wrapper chat_desc pl-3 pr-3"
                        className="wscroll px-1"
                        ref={messagesEndRef}
                        id={`chat-sc-${props.chat.id}`}
                        style={{
                          top: 0,
                          right: 0,
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column-reverse",
                          left: 0,
                          flex: 1,
                        }}
                      >
                        {isGettingChats && page == 1 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: "100%",
                              width: "100%",
                              position: "absolute",
                              zIndex: 100,
                            }}
                          >
                            <div style={{ flex: 1 }} />
                            <div style={{ alignSelf: "center" }}>
                              <Oval
                                height={40}
                                width={40}
                                color={KJUUP_COLORS.base_color}
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor={"#fff"}
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                              />
                            </div>
                            <div style={{ flex: 1 }} />
                          </div>
                        ) : (
                          <></>
                        )}

                        <InfiniteScroll
                          dataLength={
                            tempChatHistory?.length
                              ? tempChatHistory[0].data.length
                              : []
                          }
                          // next={() => fetchMoreData(props.chat.id)}
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                            paddingBottom: 20,
                            paddingTop: 100,
                            flex: 1,
                            // backgroundColor:"blue",
                            // height:"100%",
                            // width:"100%",
                          }} //To put endMessage and loader to the top.
                          inverse={true}
                          hasMore={hasMore}
                          loader={
                            isGettingChats && page > 1 ? (
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  zIndex: -1,
                                }}
                              >
                                <Oval
                                  height={30}
                                  width={30}
                                  color={KJUUP_COLORS.base_color}
                                  wrapperStyle={{ marginBottom: 20 }}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor={"#fff"}
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            ) : (
                              <></>
                            )
                          }
                          scrollableTarget={`chat-sc-${props.chat.id}`}
                          scrollThreshold={1}
                          onScroll={(e) => {
                            const bottom =
                              e.target.scrollHeight + e.target.scrollTop ===
                              e.target.clientHeight ||
                              e.target.scrollHeight + e.target.scrollTop ===
                              e.target.clientHeight + 1 ||
                              e.target.scrollHeight + e.target.scrollTop ===
                              e.target.clientHeight + 0.5;
                            if (bottom && hasMore && !isGettingChats) {
                              fetchMoreData(props.chat.id);
                            }
                          }}
                        >
                          {tempChatHistory &&
                            tempChatHistory
                              ?.filter((item) => item.id === props.chat.id)?.[0]
                              ?.data.sort(
                                (a, b) =>
                                  new Date(b.createdAt) - new Date(a.createdAt)
                              )
                              .map((chat, sindex) => {
                                return (
                                  <div
                                    key={sindex}
                                    style={{ marginBottom: "30px" }}
                                  >
                                    <div className="date_chat">
                                      <p>{chat.createdAt}</p>
                                    </div>
                                    {chat.items &&
                                      chat.items.map((chat, index) => {
                                        if (
                                          chat.message &&
                                          typeof chat.message === "string" &&
                                          !chat.all
                                        ) {
                                          let all = [];
                                          let dec = chat.message;
                                          let datax = extractUrls(secretKey ? decryptMessage(chat.message, secretKey) : dec);
                                          datax =
                                            datax && Array.isArray(datax)
                                              ? datax?.map((e) => {
                                                return e.replace("&quot", "");
                                              })
                                              : datax;
                                          let data = [...new Set(datax)];
                                          if (data && data.length > 0) {
                                            for (let obj of data) {
                                              let x = {
                                                href: obj,
                                              };
                                              let lnk = obj;
                                              if (lnk.match("vimeo")) {
                                                x.videoType = "vimeo";
                                                all.push(x);
                                              }
                                              if (
                                                lnk.match("youtube") ||
                                                lnk.match("youtu")
                                              ) {
                                                x.videoType = "youtube";
                                                all.push(x);
                                              }
                                            }
                                          }
                                          if (
                                            tempChatHistory &&
                                            tempChatHistory.length
                                          ) {
                                            let x = tempChatHistory?.filter(
                                              (item) =>
                                                item.id === props.chat.id
                                            )?.[0];
                                            x.data[sindex]["items"][index].all =
                                              all;
                                            let _xx = [];
                                            _xx.push(x);
                                            setTempChatHistory(_xx);
                                          }
                                        }
                                        return (
                                          <div
                                            style={{
                                              zIndex: 1,
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <div style={{ marginRight: 10 }}>
                                              {forward &&
                                                chat.message !==
                                                "You have blocked this contact" &&
                                                !chat.isDeleted && (
                                                  <>
                                                    <label className="container_checkbox m-0">
                                                      <input
                                                        type="checkbox"
                                                        onClick={() =>
                                                          selectIds(chat)
                                                        }
                                                      />
                                                      <span className="checkmark" />
                                                    </label>
                                                  </>
                                                )}
                                              {delMessage && (
                                                <div className="checkbox-div">
                                                  {delMessage &&
                                                    chat.message !==
                                                    "You have blocked this contact" &&
                                                    !chat.isDeleted &&
                                                    chat.message !== null &&
                                                    chat.message !== "" &&
                                                    currentTime.diff(
                                                      chat.createdAt,
                                                      "minutes"
                                                    ) <= 10 &&
                                                    chat.senderId ===
                                                    user?.id && (
                                                      <label className="container_checkbox m-0">
                                                        <input
                                                          type="checkbox"
                                                          onClick={() =>
                                                            selectIds(chat)
                                                          }
                                                        />
                                                        <span className="checkmark" />
                                                      </label>
                                                    )}
                                                  {delMessage &&
                                                    chat.message !==
                                                    "You have blocked this contact" &&
                                                    !chat.isDeleted &&
                                                    (chat.messageType ===
                                                      "doc" ||
                                                      chat.messageType ===
                                                      "audio") &&
                                                    currentTime.diff(
                                                      chat.createdAt,
                                                      "minutes"
                                                    ) <= 10 &&
                                                    chat.senderId ===
                                                    user?.id && (
                                                      <label className="container_checkbox m-0">
                                                        <input
                                                          type="checkbox"
                                                          onClick={() =>
                                                            selectIds(chat)
                                                          }
                                                        />
                                                        <span className="checkmark" />
                                                      </label>
                                                    )}
                                                </div>
                                              )}
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              {!chat.userDeactivated &&
                                                !chat.userDeleted &&
                                                !chat?.userReactivate ? (
                                                <div
                                                  className={
                                                    chat.senderId !== user?.id
                                                      ? "forword-conver del-other"
                                                      : "forword-conver"
                                                  }
                                                >
                                                  <div>
                                                    {forward && (
                                                      <div className="checkbox-div">
                                                        {(chat.messageType ===
                                                          "audio" ||
                                                          chat.messageType ===
                                                          "image" ||
                                                          chat.messageType ===
                                                          "doc" ||
                                                          chat.messageType ===
                                                          "video") && (
                                                            <label className="container_checkbox m-0">
                                                              <input
                                                                type="checkbox"
                                                                onClick={() =>
                                                                  selectIds(chat)
                                                                }
                                                              />
                                                              <span className="checkmark" />
                                                            </label>
                                                          )}
                                                      </div>
                                                    )}

                                                    {chat.message ===
                                                      "You have blocked this contact" ? (
                                                      <div className="text-center">
                                                        <div
                                                          onClick={() =>
                                                            toggleBlockUser(
                                                              props.chat.id
                                                            )
                                                          }
                                                          className="p-1 m-2 d-inline-block pointer"
                                                          style={{
                                                            background:
                                                              "#b1b1b1",
                                                            borderRadius: 5,
                                                            color: "white",
                                                          }}
                                                        >
                                                          {chat.message}. Click
                                                          to remove the
                                                          blocking.
                                                        </div>
                                                      </div>
                                                    ) : !chat.message &&
                                                      chat.messageType ===
                                                      "simple" ? (
                                                      <div className="text-center">
                                                        <div
                                                          className="p-1 m-2 d-inline-block"
                                                          style={{
                                                            background:
                                                              "#b1b1b1",
                                                            borderRadius: 5,
                                                            color: "white",
                                                          }}
                                                        >
                                                          You have unblocked
                                                          this contact.
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        key={index}
                                                        className={
                                                          chat.senderId !==
                                                            user?.id
                                                            ? "user_chat_content"
                                                            : "user_chat_content right_users"
                                                        }
                                                      >
                                                        {chat.senderId !==
                                                          user?.id ? (
                                                          <div>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <div className="mt-2">
                                                                {chat.messageType !==
                                                                  "contact" ? (
                                                                  <div>
                                                                    {!chat.isDeleted &&
                                                                      chat.message !==
                                                                      "You have blocked this contact" ? (
                                                                      <div
                                                                        className={`user_info_chat left_users ${chat
                                                                          ?.userEmoji
                                                                          ?.length >
                                                                          0
                                                                          ? "pb-4"
                                                                          : "pb-1"
                                                                          } `}
                                                                        id={`chat-${chat.id}`}
                                                                      >
                                                                        {chat.images &&
                                                                          chat
                                                                            .images
                                                                            .length >
                                                                          1 ? (
                                                                          <></>
                                                                        ) : (
                                                                          <div className="contextMenu left-context">
                                                                            <Dropdown>
                                                                              <Dropdown.Toggle
                                                                                id="dropdown-basic"
                                                                                className="toggleMenu pointer"
                                                                              >
                                                                                <FiChevronDown
                                                                                  size={
                                                                                    25
                                                                                  }
                                                                                  className="angleDownIcon"
                                                                                />
                                                                              </Dropdown.Toggle>

                                                                              <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                  onClick={() => {
                                                                                    handleReply(
                                                                                      chat
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Antworten"
                                                                                    : "Reply"}
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                  onClick={
                                                                                    handleForward
                                                                                  }
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Weiterleiten"
                                                                                    : "Forward Message"}
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                  onClick={() => {
                                                                                    handleTranslate(
                                                                                      chat
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Übersetzen"
                                                                                    : "Translate"}
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                  onClick={() =>
                                                                                    handleCopy(
                                                                                      chat
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Kopieren"
                                                                                    : "Copy"}
                                                                                </Dropdown.Item>
                                                                                {chat.messageType === "image" ?
                                                                                  <Dropdown.Item
                                                                                    onClick={() =>
                                                                                      handleCopyImage(
                                                                                        chat
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    {isGerm
                                                                                      ? "Kopieren Image"
                                                                                      : "Copy Image"}
                                                                                  </Dropdown.Item>
                                                                                  :
                                                                                  <></>
                                                                                }
                                                                              </Dropdown.Menu>
                                                                            </Dropdown>
                                                                          </div>
                                                                        )}
                                                                        {chat.isForward && (
                                                                          <p className="color-gray m-0">
                                                                            <BsFillReplyFill
                                                                              size={
                                                                                15
                                                                              }
                                                                              style={{
                                                                                fill: "#cebdbd",
                                                                                marginBottom: 3,
                                                                              }}
                                                                            />{" "}
                                                                            Forwarded
                                                                          </p>
                                                                        )}
                                                                        {chat.all &&
                                                                          chat
                                                                            .all
                                                                            .length >
                                                                          0 && (
                                                                            <div
                                                                              style={{
                                                                                marginBottom: 10,
                                                                                marginRight: 10,
                                                                                marginLeft: 10,
                                                                                marginTop: 5,
                                                                              }}
                                                                            >
                                                                              <PlayerFeed
                                                                                Links={
                                                                                  chat.all
                                                                                }
                                                                                article={{}}
                                                                              />
                                                                            </div>
                                                                          )}
                                                                        {chat.messageType ===
                                                                          "simple" &&
                                                                          chat.message !==
                                                                          "You have blocked this contact" ? (
                                                                          <div>
                                                                            {chat.isReply && (
                                                                              <div
                                                                                className="reply-left pointer"
                                                                                onClick={() =>
                                                                                  handleAnchorRef(
                                                                                    `chat-${chat.inReplyTo.id}`,
                                                                                    props
                                                                                      .chat
                                                                                      .id
                                                                                  )
                                                                                }
                                                                              >
                                                                                {chat
                                                                                  .inReplyTo
                                                                                  .messageType ===
                                                                                  "contact" && (
                                                                                    <div className="right_msg_reply">
                                                                                      <div className="right_reply_img">
                                                                                        <img
                                                                                          style={{
                                                                                            height: 45,
                                                                                            width: 45,
                                                                                          }}
                                                                                          src={
                                                                                            chat
                                                                                              .inReplyTo
                                                                                              ?.contacts[0]
                                                                                              ?.avatar
                                                                                              ? BASE_URL +
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                ?.contacts[0]
                                                                                                ?.avatar
                                                                                              : process
                                                                                                .env
                                                                                                .PUBLIC_URL +
                                                                                              "/assets/img/head.svg"
                                                                                          }
                                                                                          alt=""
                                                                                        />
                                                                                      </div>
                                                                                      <p
                                                                                        className={`m-0 simple-msg-${props.chat.id}`}
                                                                                      >
                                                                                        <span className="other-message">
                                                                                          {" "}
                                                                                          {
                                                                                            chat
                                                                                              .inReplyTo
                                                                                              ?.contacts[0]
                                                                                              .fullName
                                                                                          }
                                                                                        </span>
                                                                                      </p>
                                                                                    </div>
                                                                                  )}
                                                                                {chat
                                                                                  .inReplyTo
                                                                                  .messageType ===
                                                                                  "map" && (
                                                                                    <div className="row d-block">
                                                                                      <div className="col-12">
                                                                                        <p className="m-0">
                                                                                          <span className="other-message">
                                                                                            {chat.inReplyTo.message
                                                                                              .replace(
                                                                                                /<br\s*[/]?>/gi,
                                                                                                "\n"
                                                                                              ) // Replace <br> tags with newline
                                                                                              .replace(
                                                                                                /<[^>]+>/g,
                                                                                                ""
                                                                                              )
                                                                                              .replace(
                                                                                                /&nbsp;/g,
                                                                                                " "
                                                                                              )
                                                                                              .includes(
                                                                                                "Meeting"
                                                                                              )
                                                                                              ? "Meeting Point"
                                                                                              : "Location"}
                                                                                          </span>
                                                                                        </p>
                                                                                      </div>
                                                                                      <div className="right_reply_img col-12">
                                                                                        <GoogleMap
                                                                                          style={{
                                                                                            width: 60,
                                                                                          }}
                                                                                        />
                                                                                      </div>
                                                                                    </div>
                                                                                  )}
                                                                                {chat
                                                                                  .inReplyTo
                                                                                  .messageType ===
                                                                                  "audio" && (
                                                                                    <div className="pointer">
                                                                                      <div className="d-flex align-items-center pt-1">
                                                                                        <div className="user-avatar">
                                                                                          <img
                                                                                            src={
                                                                                              chat
                                                                                                ?.inReplyTo
                                                                                                ?.senderAvatar
                                                                                                ? BASE_URL +
                                                                                                chat
                                                                                                  ?.inReplyTo
                                                                                                  ?.senderAvatar
                                                                                                : process
                                                                                                  .env
                                                                                                  .PUBLIC_URL +
                                                                                                "/assets/img/Head-Plain.svg"
                                                                                            }
                                                                                            alt=""
                                                                                          />
                                                                                        </div>
                                                                                        <div className="audio-file">
                                                                                          <audio
                                                                                            controls
                                                                                          >
                                                                                            <source
                                                                                              src={
                                                                                                chat.local
                                                                                                  ? chat
                                                                                                    .fileUrl[0]
                                                                                                  : chat.fileUrl &&
                                                                                                    Array.isArray(
                                                                                                      chat.fileUrl
                                                                                                    )
                                                                                                    ? BASE_URL +
                                                                                                    chat
                                                                                                      .fileUrl[0]
                                                                                                    : chat.fileUrl
                                                                                                      ? BASE_URL +
                                                                                                      chat.fileUrl
                                                                                                      : ""
                                                                                              }
                                                                                              type="audio/mp3"
                                                                                            />
                                                                                          </audio>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  )}
                                                                                {chat
                                                                                  .inReplyTo
                                                                                  .messageType ===
                                                                                  "video" && (
                                                                                    <div className="video-message">
                                                                                      <video
                                                                                        height="294"
                                                                                        controls
                                                                                        width="294"
                                                                                      >
                                                                                        <source
                                                                                          src={
                                                                                            chat
                                                                                              .inReplyTo
                                                                                              .fileUrl &&
                                                                                              Array.isArray(
                                                                                                chat
                                                                                                  .inReplyTo
                                                                                                  .fileUrl
                                                                                              )
                                                                                              ? BASE_URL +
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                .fileUrl[0]
                                                                                              : chat
                                                                                                .inReplyTo
                                                                                                .fileUrl
                                                                                                ? BASE_URL +
                                                                                                chat
                                                                                                  .inReplyTo
                                                                                                  .fileUrl
                                                                                                : ""
                                                                                          }
                                                                                          type="video/webm"
                                                                                        ></source>
                                                                                      </video>
                                                                                    </div>
                                                                                  )}

                                                                                {chat
                                                                                  ?.inReplyTo
                                                                                  ?.fileUrl &&
                                                                                  chat
                                                                                    .inReplyTo
                                                                                    .messageType ===
                                                                                  "image" && (
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        flexDirection:
                                                                                          "row",
                                                                                        flex: 1,
                                                                                      }}
                                                                                    >
                                                                                      <div
                                                                                        style={{
                                                                                          width:
                                                                                            "22vh",
                                                                                          height:
                                                                                            "5.5vh",
                                                                                        }}
                                                                                      >
                                                                                        <div
                                                                                          style={{
                                                                                            flexDirection:
                                                                                              "row",
                                                                                            display:
                                                                                              "flex",
                                                                                          }}
                                                                                        >
                                                                                          <p
                                                                                            style={{
                                                                                              color:
                                                                                                "#4693FD",
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          >
                                                                                            {chat
                                                                                              ?.inReplyTo
                                                                                              .senderId !==
                                                                                              user?.id
                                                                                              ? chat
                                                                                                ?.inReplyTo
                                                                                                .senderName
                                                                                              : isGerm
                                                                                                ? DE.you
                                                                                                : EN.you}
                                                                                          </p>
                                                                                        </div>
                                                                                        <div
                                                                                          style={{
                                                                                            flexDirection:
                                                                                              "row",
                                                                                            display:
                                                                                              "flex",
                                                                                            marginTop:
                                                                                              -10,
                                                                                          }}
                                                                                        >
                                                                                          <div>
                                                                                            <img
                                                                                              width={
                                                                                                "20px"
                                                                                              }
                                                                                              height={
                                                                                                "20px"
                                                                                              }
                                                                                              src={
                                                                                                process
                                                                                                  .env
                                                                                                  .PUBLIC_URL +
                                                                                                "/assets/img/add-media.svg"
                                                                                              }
                                                                                              alt="Generic placeholder"
                                                                                              style={{
                                                                                                objectFit:
                                                                                                  "contain",
                                                                                              }}
                                                                                            />
                                                                                          </div>
                                                                                          <p
                                                                                            style={{
                                                                                              color:
                                                                                                "grey",
                                                                                              fontSize: 12,
                                                                                              marginTop: 2,
                                                                                              marginLeft: 5,
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              "Photo"
                                                                                            }
                                                                                          </p>
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          flex: 1,
                                                                                        }}
                                                                                      >
                                                                                        <img
                                                                                          src={
                                                                                            chat.local
                                                                                              ? chat
                                                                                                .fileUrl[0]
                                                                                              : BASE_URL +
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                ?.fileUrl[0]
                                                                                          }
                                                                                          style={{
                                                                                            height: 50,
                                                                                            width: 50,
                                                                                          }}
                                                                                          alt=""
                                                                                        />
                                                                                      </div>
                                                                                    </div>
                                                                                  )}
                                                                                <p className="m-0 simple-reply-msg">
                                                                                  <span
                                                                                    className="other-message"
                                                                                    style={{
                                                                                      whiteSpace:
                                                                                        "pre-wrap",
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                      __html:
                                                                                        urlify(
                                                                                          secretKey
                                                                                            ? decryptMessage(
                                                                                              chat
                                                                                                ?.inReplyTo
                                                                                                ?.message,
                                                                                              secretKey
                                                                                            )
                                                                                            : chat
                                                                                              ?.inReplyTo
                                                                                              ?.message
                                                                                        ),
                                                                                    }}
                                                                                  />{" "}
                                                                                </p>
                                                                              </div>
                                                                            )}
                                                                            <p
                                                                              className={`m-0 simple-msg-${props.chat.id}`}
                                                                            >
                                                                              {chat.isDeleted ? (
                                                                                <span>
                                                                                  {isGerm
                                                                                    ? DE.message_delete
                                                                                    : EN.message_delete}
                                                                                </span>
                                                                              ) : (
                                                                                <span
                                                                                  className="other-message"
                                                                                  style={{
                                                                                    whiteSpace:
                                                                                      "pre-wrap",
                                                                                  }}
                                                                                  dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                      urlify(
                                                                                        secretKey
                                                                                          ? decryptMessage(
                                                                                            chat?.message,
                                                                                            secretKey
                                                                                          )
                                                                                          : chat?.message
                                                                                      ),
                                                                                  }}
                                                                                />
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {chat.messageType ===
                                                                          "image" &&
                                                                          !chat.isDeleted ? (
                                                                          <div>
                                                                            <div
                                                                              className="group-ct-image"
                                                                              style={{
                                                                                height:
                                                                                  chat.images &&
                                                                                    chat
                                                                                      .images
                                                                                      .length >
                                                                                    1
                                                                                    ? null
                                                                                    : "220px",
                                                                                width:
                                                                                  chat.images &&
                                                                                    chat
                                                                                      .images
                                                                                      .length >
                                                                                    1
                                                                                    ? null
                                                                                    : "200px",
                                                                              }}
                                                                            >
                                                                              {_renderImages(
                                                                                chat
                                                                              )}
                                                                            </div>
                                                                            {chat.isLastImage ||
                                                                              (chat.images &&
                                                                                chat
                                                                                  .images
                                                                                  .length >
                                                                                1 &&
                                                                                !chat.local) ||
                                                                              !chat.hasMultiple ? (
                                                                              <p
                                                                                className={`m-0 p-1 simple-msg-${props.chat.id}`}
                                                                              >
                                                                                <span
                                                                                  className="user-message"
                                                                                  style={{
                                                                                    whiteSpace:
                                                                                      "pre-wrap",
                                                                                  }}
                                                                                  dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                      urlify(
                                                                                        secretKey
                                                                                          ? decryptMessage(
                                                                                            chat?.message,
                                                                                            secretKey
                                                                                          )
                                                                                          : chat?.message
                                                                                      ),
                                                                                  }}
                                                                                />
                                                                              </p>
                                                                            ) : (
                                                                              <>

                                                                              </>
                                                                            )}
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {chat.messageType ===
                                                                          "doc" &&
                                                                          !chat.isDeleted ? (
                                                                          <div
                                                                            className="pointer"
                                                                            onClick={() => {
                                                                              // setDoc(chat);
                                                                              // setViewDoc(
                                                                              //   true
                                                                              // );
                                                                            }}
                                                                          >
                                                                            <DownloadFileWithIcon
                                                                              info={
                                                                                chat
                                                                              }
                                                                            />
                                                                            <p
                                                                              className={`m-0 simple-msg-${props.chat.id}`}
                                                                            >
                                                                              {chat.isDeleted ? (
                                                                                <span>
                                                                                  {isGerm
                                                                                    ? DE.message_delete
                                                                                    : EN.message_delete}
                                                                                </span>
                                                                              ) : (
                                                                                <span
                                                                                  className="other-message"
                                                                                  style={{
                                                                                    whiteSpace:
                                                                                      "pre-wrap",
                                                                                  }}
                                                                                  dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                      urlify(
                                                                                        chat?.message
                                                                                      ),
                                                                                  }}
                                                                                />
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {chat.messageType ===
                                                                          "audio" &&
                                                                          !chat.isDeleted ? (
                                                                          <div className="pointer">
                                                                            <div className="d-flex align-items-center pt-1">
                                                                              <div className="user-avatar">
                                                                                <img
                                                                                  src={
                                                                                    chat.senderAvatar
                                                                                      ? BASE_URL +
                                                                                      chat.senderAvatar
                                                                                      : process
                                                                                        .env
                                                                                        .PUBLIC_URL +
                                                                                      "/assets/img/Head-Plain.svg"
                                                                                  }
                                                                                  alt=""
                                                                                />
                                                                              </div>

                                                                              <div
                                                                                className="audio-file"
                                                                                id={`${chat.id}`}
                                                                              >
                                                                                <audio
                                                                                  controls
                                                                                  id={`${chat.id}`}
                                                                                >
                                                                                  <source
                                                                                    id={`${chat.id}`}
                                                                                    src={
                                                                                      chat.local
                                                                                        ? chat
                                                                                          .fileUrl[0]
                                                                                        : chat.fileUrl &&
                                                                                          Array.isArray(
                                                                                            chat.fileUrl
                                                                                          )
                                                                                          ? BASE_URL +
                                                                                          chat
                                                                                            .fileUrl[0]
                                                                                          : chat.fileUrl
                                                                                            ? BASE_URL +
                                                                                            chat.fileUrl
                                                                                            : ""
                                                                                    }
                                                                                    type={
                                                                                      "audio/mp3"
                                                                                    }
                                                                                  />
                                                                                </audio>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {chat.messageType ===
                                                                          "video" &&
                                                                          !chat.isDeleted ? (
                                                                          <div className="video-message">
                                                                            <video
                                                                              height="294"
                                                                              controls
                                                                              width="294"
                                                                            >
                                                                              <source
                                                                                src={
                                                                                  BASE_URL +
                                                                                  (chat.fileUrl
                                                                                    ? chat
                                                                                      .fileUrl[0]
                                                                                    : "")
                                                                                }
                                                                                type="video/webm"
                                                                              ></source>
                                                                            </video>
                                                                            <p
                                                                              className={`m-0 simple-msg-${props.chat.id}`}
                                                                            >
                                                                              <span
                                                                                className="other-message"
                                                                                style={{
                                                                                  whiteSpace:
                                                                                    "pre-wrap",
                                                                                }}
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    urlify(
                                                                                      chat.message
                                                                                    ),
                                                                                }}
                                                                              />
                                                                            </p>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {chat.messageType ===
                                                                          "map" &&
                                                                          !chat.isDeleted ? (
                                                                          <div
                                                                            className="map"
                                                                            id={`map-${index}`}
                                                                          >
                                                                            {chat.isReply && (
                                                                              <div
                                                                                className="reply-left pointer"
                                                                                onClick={() =>
                                                                                  handleAnchorRef(
                                                                                    `chat-${chat?.inReplyTo.id}`,
                                                                                    props
                                                                                      .chat
                                                                                      .id
                                                                                  )
                                                                                }
                                                                              >
                                                                                {chat
                                                                                  ?.inReplyTo
                                                                                  .messageType ===
                                                                                  "contact" ? (
                                                                                  <div className="right_msg_reply">
                                                                                    <div className="right_reply_img">
                                                                                      <img
                                                                                        style={{
                                                                                          height: 45,
                                                                                          width: 45,
                                                                                        }}
                                                                                        src={
                                                                                          chat
                                                                                            .inReplyTo
                                                                                            ?.contacts[0]
                                                                                            ?.avatar
                                                                                            ? BASE_URL +
                                                                                            chat
                                                                                              .inReplyTo
                                                                                              ?.contacts[0]
                                                                                              ?.avatar
                                                                                            : process
                                                                                              .env
                                                                                              .PUBLIC_URL +
                                                                                            "/assets/img/head.svg"
                                                                                        }
                                                                                        alt=""
                                                                                      />
                                                                                    </div>
                                                                                    <p
                                                                                      className={`m-0 simple-reply-msg-${props.chat.id}`}
                                                                                    >
                                                                                      <span className="other-message">
                                                                                        {" "}
                                                                                        {
                                                                                          chat
                                                                                            .inReplyTo
                                                                                            ?.contacts[0]
                                                                                            .fullName
                                                                                        }
                                                                                      </span>
                                                                                    </p>
                                                                                  </div>
                                                                                ) : chat
                                                                                  .inReplyTo
                                                                                  .messageType ===
                                                                                  "map" ? (
                                                                                  <div className="row d-block">
                                                                                    <div className="col-12">
                                                                                      <p className="m-0">
                                                                                        <span
                                                                                          className="user-message"
                                                                                          style={{
                                                                                            whiteSpace:
                                                                                              "pre-wrap",
                                                                                          }}
                                                                                        >
                                                                                          {chat.inReplyTo.message
                                                                                            .replace(
                                                                                              /<br\s*[/]?>/gi,
                                                                                              "\n"
                                                                                            ) // Replace <br> tags with newline
                                                                                            .replace(
                                                                                              /<[^>]+>/g,
                                                                                              ""
                                                                                            )
                                                                                            .replace(
                                                                                              /&nbsp;/g,
                                                                                              " "
                                                                                            )
                                                                                            .includes(
                                                                                              "Meeting"
                                                                                            )
                                                                                            ? "Meeting Point"
                                                                                            : "Location"}
                                                                                        </span>
                                                                                      </p>
                                                                                    </div>
                                                                                    <div className="right_reply_img col-12">
                                                                                      <GoogleMap
                                                                                        style={{
                                                                                          width: 60,
                                                                                        }}
                                                                                      />
                                                                                    </div>
                                                                                  </div>
                                                                                ) : (
                                                                                  <div className="right_msg_reply">
                                                                                    {chat
                                                                                      ?.inReplyTo
                                                                                      ?.fileUrl ? (
                                                                                      <div className="right_reply_img">
                                                                                        <img
                                                                                          src={
                                                                                            chat.local
                                                                                              ? chat
                                                                                                .fileUrl[0]
                                                                                              : BASE_URL +
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                ?.fileUrl[0]
                                                                                          }
                                                                                          alt=""
                                                                                        />
                                                                                      </div>
                                                                                    ) : (
                                                                                      ""
                                                                                    )}
                                                                                    <p
                                                                                      className={`m-0 simple-msg-${props.chat.id}`}
                                                                                    >
                                                                                      <span
                                                                                        className="other-message"
                                                                                        style={{
                                                                                          whiteSpace:
                                                                                            "pre-wrap",
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                          __html:
                                                                                            urlify(
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                .message
                                                                                            ),
                                                                                        }}
                                                                                      />
                                                                                    </p>
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            )}
                                                                            <p className="m-0 p-1">
                                                                              {chat.message
                                                                                .replace(
                                                                                  /<br\s*[/]?>/gi,
                                                                                  "\n"
                                                                                ) // Replace <br> tags with newline
                                                                                .replace(
                                                                                  /<[^>]+>/g,
                                                                                  ""
                                                                                )
                                                                                .replace(
                                                                                  /&nbsp;/g,
                                                                                  " "
                                                                                )
                                                                                .includes(
                                                                                  "Meeting"
                                                                                )
                                                                                ? "Meeting Point"
                                                                                : "Location"}
                                                                            </p>
                                                                            <div className="">
                                                                              <a
                                                                                href={
                                                                                  chat.message
                                                                                }
                                                                                target="_blank"
                                                                              >
                                                                                <GoogleMap
                                                                                  style={{
                                                                                    width: 60,
                                                                                  }}
                                                                                />
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        <div className="clearfix" />
                                                                        <div className="small">
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              justifyContent:
                                                                                "space-between",
                                                                            }}
                                                                          >
                                                                            <div>
                                                                              {moment(
                                                                                chat.createdAt
                                                                              ).format(
                                                                                "HH:mm"
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="user_info_chat pb-1">
                                                                        <p className="m-0">
                                                                          <span>
                                                                            {isGerm
                                                                              ? DE.message_delete
                                                                              : EN.message_delete}
                                                                          </span>
                                                                        </p>
                                                                        <div className="small">
                                                                          {moment(
                                                                            chat.createdAt
                                                                          ).format(
                                                                            "HH:mm"
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : (
                                                                  <div>
                                                                    {!chat.isDeleted ? (
                                                                      <div className="contact-section">
                                                                        <div className="contact mb-2">
                                                                          {chat.contacts &&
                                                                            chat.contacts.map(
                                                                              (
                                                                                c,
                                                                                i
                                                                              ) => (
                                                                                <div
                                                                                  className="mb-2 contact-left  p-2"
                                                                                  key={
                                                                                    i
                                                                                  }
                                                                                  id={`chat-${chat.id}`}
                                                                                >
                                                                                  <div className="contextMenu left-context">
                                                                                    <Dropdown>
                                                                                      <Dropdown.Toggle
                                                                                        id="dropdown-basic"
                                                                                        className="toggleMenu pointer"
                                                                                      >
                                                                                        <FiChevronDown
                                                                                          size={
                                                                                            25
                                                                                          }
                                                                                          className="angleDownIcon"
                                                                                        />
                                                                                      </Dropdown.Toggle>

                                                                                      <Dropdown.Menu>
                                                                                        <Dropdown.Item
                                                                                          onClick={() => {
                                                                                            handleReply(
                                                                                              chat
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Antworten"
                                                                                            : "Reply"}
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                          onClick={
                                                                                            handleForward
                                                                                          }
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Weiterleiten"
                                                                                            : "Forward Message"}
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                          onClick={() => {
                                                                                            handleTranslate(
                                                                                              chat
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Übersetzen"
                                                                                            : "Translate"}
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                          onClick={() =>
                                                                                            handleCopy(
                                                                                              chat
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Kopieren"
                                                                                            : "Copy"}
                                                                                        </Dropdown.Item>
                                                                                        {chat.messageType === "image" ?
                                                                                          <Dropdown.Item
                                                                                            onClick={() =>
                                                                                              handleCopyImage(
                                                                                                chat
                                                                                              )
                                                                                            }
                                                                                          >
                                                                                            {isGerm
                                                                                              ? "Kopieren Image"
                                                                                              : "Copy Image"}
                                                                                          </Dropdown.Item>
                                                                                          :
                                                                                          <></>
                                                                                        }
                                                                                        <Dropdown.Item
                                                                                          onClick={() =>
                                                                                            deleteMessage(
                                                                                              chat
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Nachricht löschen"
                                                                                            : "Delete Message"}
                                                                                        </Dropdown.Item>
                                                                                      </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                  </div>
                                                                                  {chat.isForward && (
                                                                                    <p className="color-gray m-0">
                                                                                      <BsFillReplyFill
                                                                                        size={
                                                                                          15
                                                                                        }
                                                                                        style={{
                                                                                          fill: "#cebdbd",
                                                                                          marginBottom: 3,
                                                                                        }}
                                                                                      />{" "}
                                                                                      Forwarded
                                                                                    </p>
                                                                                  )}
                                                                                  <Link
                                                                                    to={`/user-profile/${c.id}`}
                                                                                  >
                                                                                    <div>
                                                                                      <span className="contact-name">
                                                                                        {
                                                                                          c.fullName
                                                                                        }
                                                                                      </span>
                                                                                      <img
                                                                                        className="contact-image"
                                                                                        style={{
                                                                                          borderRadius: 6,
                                                                                        }}
                                                                                        src={
                                                                                          c.avatar
                                                                                            ? BASE_URL +
                                                                                            c.avatar
                                                                                            : process
                                                                                              .env
                                                                                              .PUBLIC_URL +
                                                                                            "/assets/img/head.svg"
                                                                                        }
                                                                                        alt="Generic placeholder"
                                                                                      />
                                                                                    </div>
                                                                                  </Link>
                                                                                  <div className="clearfix" />
                                                                                  <div className="small text-right">
                                                                                    {moment(
                                                                                      chat.createdAt
                                                                                    ).format(
                                                                                      "HH:mm"
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              )
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="user_info_chat pb-1">
                                                                        <p className="m-0">
                                                                          <span>
                                                                            {isGerm
                                                                              ? DE.message_delete
                                                                              : EN.message_delete}
                                                                          </span>
                                                                        </p>
                                                                        <div className="small">
                                                                          {moment(
                                                                            chat.createdAt
                                                                          ).format(
                                                                            "HH:mm"
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </div>

                                                              <div
                                                                style={{
                                                                  margin:
                                                                    "7px 0px 0px 7px",
                                                                  position:
                                                                    "relative",
                                                                }}
                                                              >
                                                                <img
                                                                  onClick={() => {
                                                                    setEmojiModal(
                                                                      {
                                                                        [chat?.id]:
                                                                          !emojiModal[
                                                                          chat
                                                                            ?.id
                                                                          ],
                                                                      }
                                                                    );
                                                                  }}
                                                                  width={22}
                                                                  src={
                                                                    process.env
                                                                      .PUBLIC_URL +
                                                                    "/assets/img/Emojis.svg"
                                                                  }
                                                                  alt="emojiPicker"
                                                                />
                                                              </div>

                                                              {emojiModal[
                                                                chat?.id
                                                              ] && (
                                                                  <div
                                                                    ref={emojiRef}
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      transform:
                                                                        "translate(-0%, -90%)",
                                                                      zIndex:
                                                                        "999",
                                                                    }}
                                                                  >
                                                                    <EmojiPicker
                                                                      reactionsDefaultOpen={
                                                                        true
                                                                      }
                                                                      skinTonesDisabled
                                                                      searchPlaceholder={
                                                                        isGerm
                                                                          ? DE.search
                                                                          : EN.search
                                                                      }
                                                                      onEmojiClick={(
                                                                        e
                                                                      ) =>
                                                                        handleEmojiReaction(
                                                                          e,
                                                                          chat?.id
                                                                        )
                                                                      }
                                                                      onReactionClick={(
                                                                        e
                                                                      ) =>
                                                                        handleEmojiReaction(
                                                                          e,
                                                                          chat?.id
                                                                        )
                                                                      }
                                                                      categories={
                                                                        emojiCategories
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}
                                                            </div>
                                                            {chat?.userEmoji
                                                              ?.length > 0 && (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    gap: "5px",
                                                                    position:
                                                                      "relative",
                                                                    top: "-21px",
                                                                    flexWrap:
                                                                      "wrap",
                                                                  }}
                                                                >
                                                                  {chat?.userEmoji?.map(
                                                                    (
                                                                      emojiInfo
                                                                    ) => (
                                                                      <span
                                                                        className="emoji_show_section"
                                                                        onClick={() =>
                                                                          handleRemoveReaction(
                                                                            emojiInfo,
                                                                            chat?.id
                                                                          )
                                                                        }
                                                                      >
                                                                        {emojiDecode(
                                                                          emojiInfo?.emoji
                                                                        )}
                                                                      </span>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "end",
                                                              }}
                                                            >
                                                              {chat.messageType !==
                                                                "contact" ? (
                                                                <div>
                                                                  {!chat.isDeleted &&
                                                                    chat.message !==
                                                                    "You have blocked this contact" ? (
                                                                    <div
                                                                      className={`user_info_chat left_users ${chat
                                                                        ?.userEmoji
                                                                        ?.length >
                                                                        0
                                                                        ? "pb-4"
                                                                        : "pb-1"
                                                                        } `}
                                                                      id={`chat-${chat.id}`}
                                                                    >
                                                                      {chat.isReply && (
                                                                        <div
                                                                          className="reply-right pointer"
                                                                          onClick={() =>
                                                                            handleAnchorRef(
                                                                              `chat-${chat.inReplyTo.id}`,
                                                                              props
                                                                                .chat
                                                                                .id
                                                                            )
                                                                          }
                                                                        >
                                                                          {chat
                                                                            ?.inReplyTo
                                                                            ?.fileUrl &&
                                                                            chat
                                                                              ?.inReplyTo
                                                                              ?.messageType ==
                                                                            "image" ? (
                                                                            <>

                                                                            </>
                                                                          ) : (
                                                                            <p className="m-0 text-primary">
                                                                              {chat
                                                                                ?.inReplyTo
                                                                                .senderId !==
                                                                                user?.id
                                                                                ? chat
                                                                                  ?.inReplyTo
                                                                                  .senderName
                                                                                : isGerm
                                                                                  ? DE.you
                                                                                  : EN.you}
                                                                            </p>
                                                                          )}
                                                                          {chat
                                                                            .inReplyTo
                                                                            .messageType ===
                                                                            "contact" && (
                                                                              <div className="right_msg_reply">
                                                                                <div className="right_reply_img">
                                                                                  <img
                                                                                    style={{
                                                                                      height: 45,
                                                                                      width: 45,
                                                                                    }}
                                                                                    src={
                                                                                      chat
                                                                                        .inReplyTo
                                                                                        ?.contacts[0]
                                                                                        ?.avatar
                                                                                        ? BASE_URL +
                                                                                        chat
                                                                                          .inReplyTo
                                                                                          ?.contacts[0]
                                                                                          ?.avatar
                                                                                        : process
                                                                                          .env
                                                                                          .PUBLIC_URL +
                                                                                        "/assets/img/head.svg"
                                                                                    }
                                                                                    alt=""
                                                                                  />
                                                                                </div>
                                                                                <p
                                                                                  className={`m-0 simple-msg-${props.chat.id}`}
                                                                                >
                                                                                  <span
                                                                                    className="user-message"
                                                                                    style={{
                                                                                      whiteSpace:
                                                                                        "pre-wrap",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      chat
                                                                                        .inReplyTo
                                                                                        ?.contacts[0]
                                                                                        .fullName
                                                                                    }
                                                                                  </span>
                                                                                </p>
                                                                              </div>
                                                                            )}
                                                                          {chat
                                                                            .inReplyTo
                                                                            .messageType ===
                                                                            "map" && (
                                                                              <div className="row d-block">
                                                                                <div className="col-12">
                                                                                  <p className="m-0">
                                                                                    {chat.inReplyTo.message
                                                                                      .replace(
                                                                                        /<br\s*[/]?>/gi,
                                                                                        "\n"
                                                                                      ) // Replace <br> tags with newline
                                                                                      .replace(
                                                                                        /<[^>]+>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .includes(
                                                                                        "Meeting"
                                                                                      )
                                                                                      ? "Meeting Point"
                                                                                      : "Location"}
                                                                                  </p>
                                                                                </div>
                                                                                <div className="right_reply_img col-12">
                                                                                  <GoogleMap
                                                                                    style={{
                                                                                      width: 60,
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                            )}
                                                                          {chat
                                                                            .inReplyTo
                                                                            .messageType ===
                                                                            "audio" && (
                                                                              <div className="pointer">
                                                                                <div className="d-flex align-items-center pt-1">
                                                                                  <div className="user-avatar">
                                                                                    <img
                                                                                      src={
                                                                                        chat
                                                                                          ?.inReplyTo
                                                                                          ?.senderAvatar
                                                                                          ? BASE_URL +
                                                                                          chat
                                                                                            ?.inReplyTo
                                                                                            ?.senderAvatar
                                                                                          : process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                          "/assets/img/Head-Plain.svg"
                                                                                      }
                                                                                      alt=""
                                                                                    />
                                                                                  </div>
                                                                                  <div className="audio-file">
                                                                                    <audio
                                                                                      controls
                                                                                    >
                                                                                      <source
                                                                                        src={
                                                                                          chat.local
                                                                                            ? chat
                                                                                              .fileUrl[0]
                                                                                            : chat
                                                                                              .inReplyTo
                                                                                              .fileUrl &&
                                                                                              Array.isArray(
                                                                                                chat
                                                                                                  .inReplyTo
                                                                                                  .fileUrl
                                                                                              )
                                                                                              ? BASE_URL +
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                ?.fileUrl[0]
                                                                                              : chat
                                                                                                .inReplyTo
                                                                                                .fileUrl
                                                                                                ? chat
                                                                                                  .inReplyTo
                                                                                                  .fileUrl
                                                                                                : ""
                                                                                        }
                                                                                        type="audio/mp3"
                                                                                      />
                                                                                    </audio>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            )}
                                                                          {chat
                                                                            .inReplyTo
                                                                            .messageType ===
                                                                            "video" && (
                                                                              <div className="video-message">
                                                                                <video
                                                                                  height="294"
                                                                                  controls
                                                                                  width="294"
                                                                                >
                                                                                  <source
                                                                                    src={
                                                                                      BASE_URL +
                                                                                      chat
                                                                                        .inReplyTo
                                                                                        .fileUrl[0]
                                                                                    }
                                                                                    type="video/webm"
                                                                                  ></source>
                                                                                </video>
                                                                              </div>
                                                                            )}
                                                                          {chat
                                                                            ?.inReplyTo
                                                                            ?.messageType ===
                                                                            "simple" && (
                                                                              <div>
                                                                                <p
                                                                                  className={`m-0 simple-reply-msg-${props.chat.id}`}
                                                                                >
                                                                                  <span
                                                                                    className="user-message"
                                                                                    style={{
                                                                                      whiteSpace:
                                                                                        "pre-wrap",
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                      __html:
                                                                                        urlify(
                                                                                          secretKey
                                                                                            ? decryptMessage(
                                                                                              chat
                                                                                                .inReplyTo
                                                                                                .message,
                                                                                              secretKey
                                                                                            )
                                                                                            : chat
                                                                                              .inReplyTo
                                                                                              .message
                                                                                        ),
                                                                                    }}
                                                                                  />{" "}
                                                                                </p>
                                                                              </div>
                                                                            )}

                                                                          {chat
                                                                            ?.inReplyTo
                                                                            ?.fileUrl &&
                                                                            chat
                                                                              ?.inReplyTo
                                                                              ?.messageType ==
                                                                            "image" && (
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  flexDirection:
                                                                                    "row",
                                                                                }}
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    width:
                                                                                      "22vh",
                                                                                    height:
                                                                                      "5.5vh",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      flexDirection:
                                                                                        "row",
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <p
                                                                                      style={{
                                                                                        color:
                                                                                          "#4693FD",
                                                                                        fontSize: 12,
                                                                                      }}
                                                                                    >
                                                                                      {chat
                                                                                        ?.inReplyTo
                                                                                        .senderId !==
                                                                                        user?.id
                                                                                        ? chat
                                                                                          ?.inReplyTo
                                                                                          .senderName
                                                                                        : isGerm
                                                                                          ? DE.you
                                                                                          : EN.you}
                                                                                    </p>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      flexDirection:
                                                                                        "row",
                                                                                      display:
                                                                                        "flex",
                                                                                      marginTop:
                                                                                        -10,
                                                                                    }}
                                                                                  >
                                                                                    <div>
                                                                                      <img
                                                                                        width={
                                                                                          "20px"
                                                                                        }
                                                                                        height={
                                                                                          "20px"
                                                                                        }
                                                                                        src={
                                                                                          process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                          "/assets/img/add-media.svg"
                                                                                        }
                                                                                        alt="Generic placeholder"
                                                                                        style={{
                                                                                          objectFit:
                                                                                            "contain",
                                                                                        }}
                                                                                      />
                                                                                    </div>
                                                                                    <p
                                                                                      style={{
                                                                                        color:
                                                                                          "grey",
                                                                                        fontSize: 12,
                                                                                        marginTop: 2,
                                                                                        marginLeft: 5,
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        "Photo"
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                </div>
                                                                                <div
                                                                                  style={{
                                                                                    flex: 1,
                                                                                  }}
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      chat.local
                                                                                        ? chat
                                                                                          .fileUrl[0]
                                                                                        : BASE_URL +
                                                                                        chat
                                                                                          .inReplyTo
                                                                                          ?.fileUrl[0]
                                                                                    }
                                                                                    style={{
                                                                                      height: 50,
                                                                                      width: 50,
                                                                                    }}
                                                                                    alt=""
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                            )}

                                                                          {chat
                                                                            ?.inReplyTo
                                                                            ?.fileUrl &&
                                                                            chat
                                                                              ?.inReplyTo
                                                                              ?.messageType !==
                                                                            "image" && (
                                                                              <div className="right_msg_reply">
                                                                                {chat
                                                                                  ?.inReplyTo
                                                                                  ?.messageType !==
                                                                                  "doc" && (
                                                                                    <div className="right_reply_img">
                                                                                      {chat
                                                                                        ?.inReplyTo
                                                                                        ?.messageType ===
                                                                                        "image" && (
                                                                                          <img
                                                                                            src={
                                                                                              chat.local
                                                                                                ? chat
                                                                                                  .fileUrl[0]
                                                                                                : BASE_URL +
                                                                                                chat
                                                                                                  .inReplyTo
                                                                                                  ?.fileUrl[0]
                                                                                            }
                                                                                            alt=""
                                                                                          />
                                                                                        )}
                                                                                    </div>
                                                                                  )}
                                                                                <p
                                                                                  className={`m-0 simple-msg-${props.chat.id}`}
                                                                                >
                                                                                  {chat
                                                                                    ?.inReplyTo
                                                                                    ?.messageType ===
                                                                                    "doc" ? (
                                                                                    <span
                                                                                      className="user-message"
                                                                                      style={{
                                                                                        whiteSpace:
                                                                                          "pre-wrap",
                                                                                      }}
                                                                                    >
                                                                                      {" "}
                                                                                      <AiOutlineFile />{" "}
                                                                                      {
                                                                                        chat
                                                                                          .inReplyTo
                                                                                          .attachedFileName
                                                                                      }
                                                                                    </span>
                                                                                  ) : (
                                                                                    <span
                                                                                      className="user-message"
                                                                                      style={{
                                                                                        whiteSpace:
                                                                                          "pre-wrap",
                                                                                      }}
                                                                                      dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                          urlify(
                                                                                            chat
                                                                                              .inReplyTo
                                                                                              .message
                                                                                          ),
                                                                                      }}
                                                                                    />
                                                                                  )}
                                                                                </p>
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                      )}
                                                                      {chat.images &&
                                                                        chat
                                                                          .images
                                                                          .length >
                                                                        1 ? (
                                                                        <></>
                                                                      ) : (
                                                                        <div className="contextMenu">
                                                                          <Dropdown>
                                                                            <Dropdown.Toggle
                                                                              id="dropdown-basic"
                                                                              className="toggleMenu pointer"
                                                                            >
                                                                              <FiChevronDown
                                                                                size={
                                                                                  25
                                                                                }
                                                                                className="angleDownIcon"
                                                                              />
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                              <Dropdown.Item
                                                                                onClick={() => {
                                                                                  handleReply(
                                                                                    chat
                                                                                  );
                                                                                }}
                                                                              >
                                                                                {isGerm
                                                                                  ? "Antworten"
                                                                                  : "Reply"}
                                                                              </Dropdown.Item>
                                                                              <Dropdown.Item
                                                                                onClick={
                                                                                  handleForward
                                                                                }
                                                                              >
                                                                                {isGerm
                                                                                  ? "Weiterleiten"
                                                                                  : "Forward Message"}
                                                                              </Dropdown.Item>
                                                                              <Dropdown.Item
                                                                                onClick={() =>
                                                                                  handleMessageInfo(
                                                                                    chat
                                                                                  )
                                                                                }
                                                                              >
                                                                                {isGerm
                                                                                  ? "Info"
                                                                                  : "Message Info"}
                                                                              </Dropdown.Item>
                                                                              <Dropdown.Item
                                                                                onClick={() =>
                                                                                  handleCopy(
                                                                                    chat
                                                                                  )
                                                                                }
                                                                              >
                                                                                {isGerm
                                                                                  ? "Kopieren"
                                                                                  : "Copy"}
                                                                              </Dropdown.Item>
                                                                              {chat.messageType === "image" ?
                                                                                <Dropdown.Item
                                                                                  onClick={() =>
                                                                                    handleCopyImage(
                                                                                      chat
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Kopieren Image"
                                                                                    : "Copy Image"}
                                                                                </Dropdown.Item>
                                                                                :
                                                                                <></>
                                                                              }
                                                                              {currentTime.diff(
                                                                                chat.createdAt,
                                                                                "minutes"
                                                                              ) <=
                                                                                10 ? (
                                                                                <Dropdown.Item
                                                                                  onClick={() =>
                                                                                    deleteMessage(
                                                                                      chat
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {isGerm
                                                                                    ? "Nachricht löschen"
                                                                                    : "Delete Message"}
                                                                                </Dropdown.Item>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </Dropdown.Menu>
                                                                          </Dropdown>
                                                                        </div>
                                                                      )}
                                                                      {chat?.all
                                                                        ?.length >
                                                                        0 && (
                                                                          <div
                                                                            style={{
                                                                              marginBottom: 10,
                                                                              marginRight: 10,
                                                                              marginLeft: 10,
                                                                              marginTop: 5,
                                                                            }}
                                                                          >
                                                                            <PlayerFeed
                                                                              Links={
                                                                                chat.all
                                                                              }
                                                                              article={{}}
                                                                            />
                                                                          </div>
                                                                        )}
                                                                      {chat.isForward && (
                                                                        <p className="color-gray m-0">
                                                                          <BsFillReplyFill
                                                                            size={
                                                                              15
                                                                            }
                                                                            style={{
                                                                              fill: "#cebdbd",
                                                                              marginBottom: 3,
                                                                            }}
                                                                          />{" "}
                                                                          Forwarded
                                                                        </p>
                                                                      )}
                                                                      {chat.messageType ===
                                                                        "simple" &&
                                                                        chat.message !==
                                                                        "You have blocked this contact" ? (
                                                                        <div>
                                                                          <p
                                                                            className={`m-0 simple-msg-${props.chat.id}`}
                                                                          >
                                                                            {chat.isDeleted ? (
                                                                              <span>
                                                                                {isGerm
                                                                                  ? DE.message_delete
                                                                                  : EN.message_delete}
                                                                              </span>
                                                                            ) : (
                                                                              <span
                                                                                className="user-message"
                                                                                style={{
                                                                                  whiteSpace:
                                                                                    "pre-wrap",
                                                                                }}
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html: `${urlify(
                                                                                    secretKey
                                                                                      ? decryptMessage(
                                                                                        escapeHtml(
                                                                                          chat?.message
                                                                                        ),
                                                                                        secretKey
                                                                                      )
                                                                                      : escapeHtml(
                                                                                        chat?.message
                                                                                      )
                                                                                  )}`,
                                                                                }}
                                                                              />
                                                                            )}
                                                                          </p>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}

                                                                      {chat.messageType ===
                                                                        "image" &&
                                                                        !chat.isDeleted ? (
                                                                        <div>
                                                                          <div
                                                                            className="group-ct-image"
                                                                            style={{
                                                                              height:
                                                                                chat.images &&
                                                                                  chat
                                                                                    .images
                                                                                    .length >
                                                                                  1
                                                                                  ? null
                                                                                  : "220px",
                                                                              width:
                                                                                chat.images &&
                                                                                  chat
                                                                                    .images
                                                                                    .length >
                                                                                  1
                                                                                  ? null
                                                                                  : "200px",
                                                                            }}
                                                                          >
                                                                            {_renderImages(
                                                                              chat
                                                                            )}
                                                                          </div>
                                                                          {chat.isLastImage ||
                                                                            (chat.images &&
                                                                              chat
                                                                                .images
                                                                                .length >
                                                                              1 &&
                                                                              !chat.local) ||
                                                                            !chat.hasMultiple ? (
                                                                            <p
                                                                              className={`m-0 p-1 simple-msg-${props.chat.id}`}
                                                                            >
                                                                              <span
                                                                                className="user-message"
                                                                                style={{
                                                                                  whiteSpace:
                                                                                    "pre-wrap",
                                                                                }}
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    urlify(
                                                                                      secretKey
                                                                                        ? decryptMessage(
                                                                                          chat?.message,
                                                                                          secretKey
                                                                                        )
                                                                                        : chat?.message
                                                                                    ),
                                                                                }}
                                                                              />
                                                                            </p>
                                                                          ) : (
                                                                            <>

                                                                            </>
                                                                          )}
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}

                                                                      {chat.messageType ===
                                                                        "doc" &&
                                                                        !chat.isDeleted ? (
                                                                        <div
                                                                          className="pointer text-left"
                                                                          onClick={() => {
                                                                            // setDoc(chat);
                                                                            // setViewDoc(
                                                                            //   true
                                                                            // );
                                                                          }}
                                                                        >
                                                                          <DownloadFileWithIcon
                                                                            info={
                                                                              chat
                                                                            }
                                                                          />
                                                                          <p className="m-0 p-1">
                                                                            <span
                                                                              className="user-message"
                                                                              style={{
                                                                                whiteSpace:
                                                                                  "pre-wrap",
                                                                              }}
                                                                              dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                  urlify(
                                                                                    chat?.message
                                                                                  ),
                                                                              }}
                                                                            />
                                                                          </p>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {chat.messageType ===
                                                                        "audio" &&
                                                                        !chat.isDeleted ? (
                                                                        <div className="pointer">
                                                                          <div className="d-flex align-items-center pt-1">
                                                                            <div className="user-avatar">
                                                                              <img
                                                                                src={
                                                                                  chat.senderAvatar
                                                                                    ? BASE_URL +
                                                                                    chat.senderAvatar
                                                                                    : process
                                                                                      .env
                                                                                      .PUBLIC_URL +
                                                                                    "/assets/img/Head-Plain.svg"
                                                                                }
                                                                                alt=""
                                                                              />
                                                                            </div>
                                                                            <div className="audio-file">
                                                                              <audio
                                                                                controls
                                                                              >
                                                                                <source
                                                                                  src={
                                                                                    chat.local
                                                                                      ? chat
                                                                                        .fileUrl[0]
                                                                                      : chat.fileUrl &&
                                                                                        Array.isArray(
                                                                                          chat.fileUrl
                                                                                        )
                                                                                        ? BASE_URL +
                                                                                        chat
                                                                                          ?.fileUrl[0]
                                                                                        : chat.fileUrl
                                                                                          ? chat.fileUrl
                                                                                          : ""
                                                                                  }
                                                                                  type="audio/mp3"
                                                                                />
                                                                              </audio>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {chat.messageType ===
                                                                        "video" &&
                                                                        !chat.isDeleted ? (
                                                                        <div className="video-message">
                                                                          <video
                                                                            height="294"
                                                                            controls
                                                                            width="294"
                                                                          >
                                                                            <source
                                                                              src={
                                                                                BASE_URL +
                                                                                (chat.fileUrl
                                                                                  ? chat
                                                                                    .fileUrl[0]
                                                                                  : "")
                                                                              }
                                                                              type="video/webm"
                                                                            ></source>
                                                                          </video>
                                                                          <p
                                                                            className={`m-0 p-1 simple-msg-${props.chat.id}`}
                                                                          >
                                                                            <span
                                                                              className="user-message"
                                                                              style={{
                                                                                whiteSpace:
                                                                                  "pre-wrap",
                                                                              }}
                                                                              dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                  urlify(
                                                                                    chat?.message
                                                                                  ),
                                                                              }}
                                                                            />
                                                                          </p>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {chat.messageType ===
                                                                        "map" &&
                                                                        !chat.isDeleted ? (
                                                                        <div
                                                                          className="map"
                                                                          id={`map-${index}`}
                                                                        >
                                                                          <p className="m-0 p-1">
                                                                            <span
                                                                              className="user-message"
                                                                              style={{
                                                                                whiteSpace:
                                                                                  "pre-wrap",
                                                                              }}
                                                                            >
                                                                              {chat.message
                                                                                .replace(
                                                                                  /<br\s*[/]?>/gi,
                                                                                  "\n"
                                                                                ) // Replace <br> tags with newline
                                                                                .replace(
                                                                                  /<[^>]+>/g,
                                                                                  ""
                                                                                )
                                                                                .replace(
                                                                                  /&nbsp;/g,
                                                                                  " "
                                                                                )
                                                                                .includes(
                                                                                  "Meeting"
                                                                                )
                                                                                ? "Meeting Point"
                                                                                : "Location"}
                                                                            </span>
                                                                          </p>
                                                                          <div className="">
                                                                            <a
                                                                              href={
                                                                                chat.message
                                                                              }
                                                                              target="_blank"
                                                                              rel="noreferrer"
                                                                            >
                                                                              <GoogleMap
                                                                                style={{
                                                                                  width: 60,
                                                                                }}
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      <div className="clearfix" />
                                                                      {!chat.isDeleted && (
                                                                        <div className="small">
                                                                          {moment(
                                                                            chat.createdAt
                                                                          ).format(
                                                                            "HH:mm"
                                                                          )}
                                                                          {chat.messageReadByAll ? (
                                                                            <BsCheckAll
                                                                              size={
                                                                                15
                                                                              }
                                                                              className="theme-color"
                                                                            />
                                                                          ) : chat.messageReachedToAll ? (
                                                                            <BsCheckAll
                                                                              size={
                                                                                15
                                                                              }
                                                                              color="#737373"
                                                                            />
                                                                          ) : (
                                                                            <BsCheck
                                                                              size={
                                                                                15
                                                                              }
                                                                              color="#737373"
                                                                            />
                                                                          )}
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <div className="user_info_chat pb-1">
                                                                      <p className="m-0">
                                                                        <span>
                                                                          {isGerm
                                                                            ? DE.message_delete
                                                                            : EN.message_delete}
                                                                        </span>
                                                                      </p>
                                                                      <div className="small">
                                                                        {moment(
                                                                          chat.createdAt
                                                                        ).format(
                                                                          "HH:mm"
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              ) : (
                                                                <div>
                                                                  {!chat.isDeleted ? (
                                                                    <div className="contact-section">
                                                                      <div className="contact mb-2">
                                                                        {chat.contacts &&
                                                                          chat.contacts.map(
                                                                            (
                                                                              c,
                                                                              i
                                                                            ) => (
                                                                              <div
                                                                                className="mb-2 contact-right  p-2"
                                                                                key={
                                                                                  i
                                                                                }
                                                                                id={`chat-${chat.id}`}
                                                                              >
                                                                                <div className="contextMenu">
                                                                                  <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                      id="dropdown-basic"
                                                                                      className="toggleMenu pointer"
                                                                                    >
                                                                                      <FiChevronDown
                                                                                        size={
                                                                                          25
                                                                                        }
                                                                                        className="angleDownIcon"
                                                                                      />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                      <Dropdown.Item
                                                                                        onClick={() => {
                                                                                          handleReply(
                                                                                            chat
                                                                                          );
                                                                                        }}
                                                                                      >
                                                                                        Reply
                                                                                      </Dropdown.Item>
                                                                                      <Dropdown.Item
                                                                                        onClick={
                                                                                          handleForward
                                                                                        }
                                                                                      >
                                                                                        Forward
                                                                                        Message
                                                                                      </Dropdown.Item>
                                                                                      <Dropdown.Item
                                                                                        onClick={() =>
                                                                                          handleMessageInfo(
                                                                                            chat
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        Message
                                                                                        Info
                                                                                      </Dropdown.Item>
                                                                                      <Dropdown.Item
                                                                                        onClick={() =>
                                                                                          handleCopy(
                                                                                            chat
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        Copy
                                                                                      </Dropdown.Item>
                                                                                      {chat.messageType === "image" ?
                                                                                        <Dropdown.Item
                                                                                          onClick={() =>
                                                                                            handleCopyImage(
                                                                                              chat
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Kopieren Image"
                                                                                            : "Copy Image"}
                                                                                        </Dropdown.Item>
                                                                                        :
                                                                                        <></>
                                                                                      }
                                                                                      {currentTime.diff(
                                                                                        chat.createdAt,
                                                                                        "minutes"
                                                                                      ) <=
                                                                                        10 ? (
                                                                                        <Dropdown.Item
                                                                                          onClick={() =>
                                                                                            deleteMessage(
                                                                                              chat
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          {isGerm
                                                                                            ? "Nachricht löschen"
                                                                                            : "Delete Message"}
                                                                                        </Dropdown.Item>
                                                                                      ) : (
                                                                                        ""
                                                                                      )}
                                                                                    </Dropdown.Menu>
                                                                                  </Dropdown>
                                                                                </div>
                                                                                {chat.isForward && (
                                                                                  <p className="color-gray m-0">
                                                                                    <BsFillReplyFill
                                                                                      size={
                                                                                        15
                                                                                      }
                                                                                      style={{
                                                                                        fill: "#cebdbd",
                                                                                        marginBottom: 3,
                                                                                      }}
                                                                                    />{" "}
                                                                                    Forwarded
                                                                                  </p>
                                                                                )}
                                                                                <Link
                                                                                  to={`/user-profile/${c.id}`}
                                                                                >
                                                                                  <div>
                                                                                    <span className="contact-name">
                                                                                      {
                                                                                        c.fullName
                                                                                      }
                                                                                    </span>
                                                                                    <img
                                                                                      className="contact-image"
                                                                                      style={{
                                                                                        borderRadius: 6,
                                                                                      }}
                                                                                      src={
                                                                                        c.avatar
                                                                                          ? BASE_URL +
                                                                                          c.avatar
                                                                                          : process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                          "/assets/img/head.svg"
                                                                                      }
                                                                                      alt="Generic placeholder"
                                                                                    />
                                                                                  </div>
                                                                                </Link>
                                                                                <div className="clearfix" />
                                                                                {!chat.isDeleted && (
                                                                                  <div className="small">
                                                                                    {moment(
                                                                                      chat.createdAt
                                                                                    ).format(
                                                                                      "HH:mm"
                                                                                    )}
                                                                                    {chat.messageReadByAll ? (
                                                                                      <BsCheckAll
                                                                                        size={
                                                                                          15
                                                                                        }
                                                                                        className="theme-color"
                                                                                      />
                                                                                    ) : chat.messageReachedToAll ? (
                                                                                      <BsCheckAll
                                                                                        size={
                                                                                          15
                                                                                        }
                                                                                        color="#737373"
                                                                                      />
                                                                                    ) : (
                                                                                      <BsCheck
                                                                                        size={
                                                                                          15
                                                                                        }
                                                                                        color="#737373"
                                                                                      />
                                                                                    )}
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            )
                                                                          )}
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="user_info_chat pb-1">
                                                                      <p className="m-0">
                                                                        <span>
                                                                          {isGerm
                                                                            ? DE.message_delete
                                                                            : EN.message_delete}
                                                                        </span>
                                                                      </p>
                                                                      <div className="small">
                                                                        {moment(
                                                                          chat.createdAt
                                                                        ).format(
                                                                          "HH:mm"
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div>
                                                              {chat?.userEmoji
                                                                ?.length >
                                                                0 && (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      gap: "5px",
                                                                      position:
                                                                        "relative",
                                                                      top: "-21px",
                                                                      flexWrap:
                                                                        "wrap",
                                                                      justifyContent:
                                                                        "end",
                                                                    }}
                                                                  >
                                                                    {chat?.userEmoji?.map(
                                                                      (
                                                                        emojiInfo
                                                                      ) => (
                                                                        <span className="emoji_show_section">
                                                                          {emojiDecode(
                                                                            emojiInfo?.emoji
                                                                          )}
                                                                        </span>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                        </InfiniteScroll>
                      </div>
                    </div>
                    // </div>
                  }
                  err={isGerm ? DE.only_in_mobileL : EN.only_in_mobileL}
                />

                <div
                  className={
                    searchView || forward || delMessage
                      ? "chat_input p-0"
                      : "chat_input p-0"
                  }
                >
                  {searchView && (
                    <div className="Chat_up_down">
                      <a className="pointer" onClick={(e) => prevHighlight(e)}>
                        <BsChevronUp
                          className={
                            totalHighlights > 1 && searchIndex !== 0
                              ? "active-forward"
                              : ""
                          }
                        />
                      </a>
                      <a className="pointer" onClick={(e) => nextHighlight(e)}>
                        <BsChevronDown
                          className={
                            searchIndex < totalHighlights - 1
                              ? "active-forward"
                              : ""
                          }
                        />
                      </a>
                      {totalHighlights > 0 && (
                        <span className="pl-5">
                          {searchIndex + 1} Of {totalHighlights} Results
                        </span>
                      )}
                    </div>
                  )}
                  {forward && (
                    <div className="Chat_up_down forword-chat">
                      <div className="forword-icon">
                        <a href="#" onClick={handleForwardMessage}>
                          <BsFillReplyFill
                            size={30}
                            className={
                              msgIds.length > 0
                                ? "active-forward"
                                : "color-gray"
                            }
                          />
                        </a>
                      </div>
                      <div className="worword_selected">
                        {msgIds.length} {isGerm ? DE.selected : EN.selected}
                      </div>
                      <div className="">
                        <a
                          onClick={cancelForward}
                          className="text-orange"
                          href="#"
                        >
                          {isGerm ? DE.cancel : EN.cancel}
                        </a>
                      </div>
                    </div>
                  )}
                  {activeReply &&
                    (replyMessage.messageType === "image" ? (
                      <div style={{ flex: 1 }} className={"reply-left"}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 20,
                            paddingLeft: 10,
                            paddingTop: 10,
                            paddingBottom: 0,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              <p style={{ color: "#4693FD", fontSize: 12 }}>
                                {replyMessage.senderId == user.id
                                  ? "You"
                                  : replyMessage.senderName}
                              </p>
                            </div>
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                marginTop: -10,
                              }}
                            >
                              <div>
                                <img
                                  width={"20px"}
                                  height={"20px"}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/add-media.svg"
                                  }
                                  alt="Generic placeholder"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <div>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: 12,
                                    marginTop: 2,
                                    marginLeft: 5,
                                  }}
                                >
                                  {"Photo"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div style={{}}>
                            <img
                              src={BASE_URL + replyMessage.fileUrl[0]}
                              style={{
                                height: 50,
                                width: 50,
                                borderRadius: 10,
                              }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div>
                          <p style={{ color: "#000", whiteSpace: "pre-wrap" }}>
                            {replyMessage.message
                              .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                              .replace(/<[^>]+>/g, "")
                              .replace(/&nbsp;/g, " ").length > 300
                              ? replyMessage.message
                                .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp;/g, " ")
                                .substr(0, 300) + "..."
                              : replyMessage.message
                                .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp;/g, " ")}
                          </p>
                        </div>
                        <div
                          className="cancel-reply"
                          style={{ marginRight: 10 }}
                        >
                          <a
                            onClick={cancelReply}
                            className="text-orange"
                            href="#"
                          >
                            X
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="reply-section">
                        <div className="reply-msg">
                          {replyMessage.senderId === user?.id ? (
                            <div className="user-reply">You</div>
                          ) : (
                            <div className="user-reply">
                              {replyMessage.senderName}
                            </div>
                          )}
                          <div
                            className={
                              replyMessage.messageType === "map" &&
                                replyMessage.messageType === "doc"
                                ? "reply-img d-none"
                                : "reply-img flex"
                            }
                            style={{
                              width:
                                replyMessage.messageType === "contact" ||
                                  replyMessage.messageType === "image"
                                  ? "100%"
                                  : "inherit",
                            }}
                          >
                            {replyMessage?.messageType == "audio" && (
                              <div className="pointer">
                                <div className="d-flex align-items-center pt-1">
                                  <div className="user-avatar">
                                    <img
                                      src={
                                        replyMessage?.senderAvatar
                                          ? BASE_URL +
                                          replyMessage?.senderAvatar
                                          : process.env.PUBLIC_URL +
                                          "/assets/img/Head-Plain.svg"
                                      }
                                      style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: 100,
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="audio-file">
                                    <audio controls>
                                      <source
                                        src={
                                          replyMessage.fileUrl &&
                                            Array.isArray(replyMessage.fileUrl)
                                            ? BASE_URL + replyMessage.fileUrl[0]
                                            : replyMessage.fileUrl
                                              ? replyMessage.fileUrl
                                              : ""
                                        }
                                        type="audio/wav"
                                      />
                                    </audio>
                                  </div>
                                </div>
                              </div>
                            )}
                            {replyMessage.messageType !== "map" &&
                              replyMessage?.messageType !== "audio" &&
                              replyMessage.messageType !== "contact" &&
                              replyMessage.messageType !== "doc" ? (
                              <></>
                            ) : (
                              ""
                            )}
                            {replyMessage.messageType === "doc" ? (
                              <p>
                                {replyMessage.messageType === "doc" && (
                                  <AiOutlineFile className="mr-1" />
                                )}
                                {replyMessage.attachedFileName}
                              </p>
                            ) : (
                              ""
                            )}
                            {replyMessage.messageType === "contact" ? (
                              <p>{replyMessage.contacts[0].fullName}</p>
                            ) : (
                              ""
                            )}
                            {replyMessage.messageType === "contact" ? (
                              <div className="img_video">
                                <img
                                  style={{ height: 45, width: 45 }}
                                  src={
                                    replyMessage.contacts[0].avatar
                                      ? BASE_URL +
                                      replyMessage.contacts[0].avatar
                                      : process.env.PUBLIC_URL +
                                      "/assets/img/head.svg"
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {replyMessage.messageType === "image" ? (
                            <div className="img_video">
                              <img src={BASE_URL + replyMessage.fileUrl[0]} />
                            </div>
                          ) : (
                            ""
                          )} */}
                            {replyMessage.messageType === "video" ? (
                              <div className="img_video">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/video.png"
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {replyMessage.messageType === "map" ? (
                            <div className="img_video">
                              <GoogleMap style={{ width: 50 }} />
                            </div>
                          ) : (
                            ""
                          )}
                          <div>
                            <p
                              style={{ color: "#000", whiteSpace: "pre-wrap" }}
                            >
                              {replyMessage.message
                                .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp;/g, " ").length > 300
                                ? replyMessage.message
                                  .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                                  .replace(/<[^>]+>/g, "")
                                  .replace(/&nbsp;/g, " ")
                                  .substr(0, 300) + "..."
                                : replyMessage.message
                                  .replace(/<br\s*[/]?>/gi, "\n") // Replace <br> tags with newline
                                  .replace(/<[^>]+>/g, "")
                                  .replace(/&nbsp;/g, " ")}
                            </p>
                          </div>
                          <div className="cancel-reply">
                            <a
                              onClick={cancelReply}
                              className="text-orange"
                              href="#"
                            >
                              X
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  {delMessage && (
                    <div
                      className="Chat_up_down forword-chat"
                      style={{ height: 40 }}
                    >
                      <div className="forword-icon">
                        <a
                          href="#"
                          onClick={() => deleteSelectedMessages(props.chat.id)}
                        >
                          <TrashIcon
                            size={30}
                            className={
                              msgIds.length > 0
                                ? "trash_icon_active"
                                : "trash_icon"
                            }
                          />
                        </a>
                      </div>
                      <div className="worword_selected">
                        {msgIds.length} {isGerm ? DE.selected : EN.selected}
                      </div>
                      <div className="">
                        <a
                          onClick={cancelForward}
                          className="text-orange"
                          href="#"
                        >
                          {isGerm ? DE.cancel : EN.cancel}
                        </a>
                      </div>
                    </div>
                  )}
                  {!audioRecording && !forward && !props.chat.isDeactivated && (
                    <div className="reply-wrapper">
                      {!searchView &&
                        !forward &&
                        !delMessage &&
                        !audioRecording && (
                          // <a href="#" onClick={() => setFloatingAction(true)}>
                          <IconButton
                            type="button"
                            onClick={() => setFloatingAction(true)}
                          >
                            <img
                              width={19}
                              src={
                                process.env.PUBLIC_URL + "/assets/img/Add.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </IconButton>
                          // </a>
                        )}
                      {!searchView &&
                        !forward &&
                        !delMessage &&
                        !audioRecording && (
                          <div className="input_text_msg">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <textarea
                                onPaste={handlePaste}
                                ref={textRef}
                                id={`text-area-${props.chat.id}`}
                                role="textarea"
                                contentEditable="true"
                                className={`customInputBox`}
                                onInput={(e) => userTyping(e, props.chat.id)}
                                onBlur={setText}
                                onKeyUp={(e) => setMessage(e.target.value)}
                                onKeyDown={(e) =>
                                  handleKeyDown(e, props.chat.id)
                                }
                                onClick={setFocus}
                                value={message}
                                style={{ height: 34 }}
                              />
                            </Form.Group>
                          </div>
                        )}
                      {!searchView &&
                        !forward &&
                        !delMessage &&
                        !audioRecording && (
                          <Popup
                            trigger={
                              <a
                                ref={ttop}
                                // data-tip="custom"
                                // data-event="click"
                                // data-for={`my-anchor-element-${props.chat.id}`}
                                id={`my-anchor-element-${props.chat.id}`}
                                href="javascript:void(0);"
                                // onClick={() => {
                                //   Tooltip.show(ttop.current)
                                // }}
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: message ? 10 : 0,
                                }}
                              >
                                <img
                                  width={22}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/Emojis.svg"
                                  }
                                  alt="Generic placeholder"
                                />
                              </a>
                            }
                            position="top right"
                            {...{ contentStyle, arrowStyle }}
                          >
                            <p>
                              <Picker
                                data={data}
                                // onClickOutside={() => Tooltip.hide(ttop.current)}
                                i18n={
                                  isGerm && {
                                    ...i18n,
                                    search: DE.search,
                                    categories: {
                                      ...i18n.categories,
                                      frequent: DE.frequent,
                                      people: DE.people,
                                    },
                                  }
                                }
                                previewPosition={"none"}
                                theme={"light"}
                                onEmojiSelect={(e) => {
                                  userTyping(null, props.chat.id, e.native);
                                }}
                              />
                            </p>
                          </Popup>
                        )}
                      {!message &&
                        !searchView &&
                        !forward &&
                        !delMessage &&
                        !audioRecording && (
                          <a href="javascript:void(0);" onClick={start}>
                            <img
                              width={17}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/voice-recording.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </a>
                        )}
                      {message && !searchView && (
                        <a
                          href="javascript:void(0);"
                          onClick={(e) => sendMessage(props.chat.id)}
                        >
                          <img
                            width={17}
                            src={
                              process.env.PUBLIC_URL + "/assets/img/Send.svg"
                            }
                            alt="Generic placeholder"
                          />
                        </a>
                      )}
                      <AudioReactRecorder
                        canvasHeight={0}
                        canvasWidth={0}
                        state={recordState}
                        onStop={(audioData) =>
                          stopRecording(audioData, props.chat.id)
                        }
                      />
                    </div>
                  )}
                  <AudioReactRecorder
                    canvasHeight={0}
                    canvasWidth={0}
                    state={recordState}
                    onStop={(audioData) =>
                      stopRecording(audioData, props.chat.id)
                    }
                  />
                  {audioRecording && (
                    <div className="row w-100 p-2 align-items-center justify-content-center">
                      <div className="col-1">
                        <a
                          onClick={() => setAudioRecording(false)}
                          className="text-orange"
                          href="#"
                        >
                          <MdClose />
                        </a>
                      </div>
                      <div className="col-10">
                        <div className="row">
                          <div className="col-10 pt-1 progress-wrapper">
                            <ProgressBar
                              className="mt-1"
                              now={recordingCounter}
                            />
                          </div>
                          <div className="col-2 pl-0">
                            {new Date(recordingCounter * 1000)
                              .toISOString()
                              .substr(11, 8)}
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        <img
                          className="pointer"
                          onClick={stop}
                          width={17}
                          src={process.env.PUBLIC_URL + "/assets/img/Send.svg"}
                          alt="Generic placeholder"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {floatingAction && (
                  <FloatingAction
                    sendDocFile={sendDocFile}
                    sendVideoMessage={sendVideoMessage}
                    showContactPage={() => {
                      setShowContactSection(true);
                      setFloatingAction(false);
                    }}
                    showLocationDialog={showLocationDialog}
                    sendImageMessage={sendImageMessage}
                    handleClose={() => {
                      setFloatingAction(false);
                      setDropFiles(null);
                    }}
                    chat={props.chat}
                    chatDetails={chatDetails}
                    setParam={() => { }}
                    dropfiles={dropfiles}
                    messagePaar={message}
                    userTyping={(e) => userTyping(e, props.chat.id)}
                    onBlur={(e) => setText(e)}
                  />
                )}

                {showTranslateDialog && (
                  <TranslateDialog
                    handleTranslateMessage={(chatMessage) =>
                      handleTranslateMessage(chatMessage, props.chat.id)
                    }
                    handleClose={() => setTranslateDialog(false)}
                    chat={chatMessage}
                    secretKey={secretKey}
                  />
                )}
              </div>
            )}
        </Animated>
      </div>
      {openEmojiPicker && (
        <EmojiPickerDialog
          handleClose={() => setEmojiPicker(false)}
          setMessage={setMessage}
          sendMessage={() => sendMessage(props.chat.id)}
        />
      )}
      {viewInfo && !mediaDoc && (
        <UserInfo
          setSearch={() => setSearchView(true)}
          viewMediaDoc={() => setMediaDoc(true)}
          handleClose={() => {
            setViewInfo(false);
            getDetails(props.chat.id);
          }}
          chatDetails={chatDetails}
          chat={props.chat}
        />
      )}
      {mediaDoc && (
        <MediaDocs
          chatHistory={chatHistory}
          closeMediaDoc={() => setMediaDoc(false)}
          chat={props.chat}
        />
      )}
      {viewDoc && (
        <DocViewer
          chat={doc}
          handleClose={() => handleCloseViewDoc(props.chat.id)}
        />
      )}
      {show && (
        <ChatShareMap
          sendMapMessage={sendMapMessage}
          chat={props.chat}
          show={show}
          handleClose={hideLocationDialog}
        />
      )}
      {showContactSection && (
        <ShareContact
          sendCont={(dadad) => {
            _sendContact(dadad);
          }}
          chat={props.chat}
          handleClose={() => setShowContactSection(false)}
        />
      )}
      {showMessageInfo && (
        <MessageInfo
          chatDetails={chatDetails}
          chatMessage={chatMessage}
          handleClose={() => {
            setMessageInfo(false);
            setChatMessage("");
          }}
        />
      )}
      {/* {showAllImages && (
        <AllImages
          handleClose={() => {
            setShowAllImages(false)
          }}
          images={showAllImages}
        />
      )} */}

      <Modal
        className="profile_image"
        show={showImage}
        onHide={() => {
          setImage("");
          setShowImage(false);
        }}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="profile_pic_profile">
            <img
              className="chat-image-preview"
              src={image}
              alt="Generic placeholder"
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    openChats,
    groups,
    messageIds,
    updateGroupMessage,
    language,
    draftMessages,
  } = state;
  return {
    groups: groups,
    language: language,
    openChats: openChats,
    messageIds: messageIds,
    updateGroupMessage: updateGroupMessage,
    draftMessages: draftMessages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateOpenChatsList: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateForwardMessage: (value) =>
      dispatch({ type: FORWARD_MESSAGE, forwardMessage: value }),
    updateMessageIds: (ids) => dispatch({ type: MESSAGE_IDS, messageIds: ids }),
    updateForOpenChatId: (id) => dispatch({ type: FOR_OPEN_CHAT_ID, id: id }),
    updateLastMessage: (msg) =>
      dispatch({ type: UPDATE_GROUP_MESSAGE, updateGroupMessage: msg }),
    updateDraftMessages: (msg) =>
      dispatch({ type: DRAFT_MESSAGES, draftMessages: msg }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Conversation));
